import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { FC, useState } from 'react';
import { Image } from 'react-bootstrap';
import { FaAward, FaHome, FaUniversity } from 'react-icons/fa';
import { MdBookmarks, MdHelp, MdLogout } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IUser } from '../../entities/user.entity';
import { AuthenticatedRoutes, AuthenticatedRoutesUser, Routes } from '../../enums/routes.enum';
import { logout } from '../../redux/slices/auth.slice';
import { closeSidebar, openSidebar, toggleSidebar } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { UserRole } from '../../enums/user-roles.enum';

interface IProps {
  user: IUser;
}

const SidebarUser: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const expanded: boolean = useSelector((s: RootState) => s.ui.sidebar.expanded);

  const [selected, setSelected] = useState<string>(AuthenticatedRoutesUser.Dashboard);

  const isSelected = (key: string) => selected ? selected.startsWith(key) : false;
  const isSelectedItem = (key: string) => selected ? selected === key : false;

  const handleSelectParent = (e: React.MouseEvent<HTMLElement>, value: string) => {
    if (!isSelectedItem(value) && expanded) return;
    if (!expanded) dispatch(openSidebar());
    if (isSelected(value)) return;
    let target: HTMLElement = e.target as HTMLElement;
    while (target.tagName.toLocaleLowerCase() !== 'div') {
      target = target.parentElement as HTMLElement;
    }
    setTimeout(() => target.click(), 200);
    setSelected(value);
  };

  const handleSelectItem = (value: string) => {
    setSelected(value);
    dispatch(closeSidebar());
  };

  const handleLogout = () => {
    dispatch(closeSidebar());
    dispatch(logout())
      .unwrap()
      .then(() => (window.location.href = Routes.Root));
  };

  return (
    <SideNav
      style={{ zIndex: 1031 }}
      className={['sidebar', !expanded && 'sidebar-collapsed']}
      expanded={expanded}
      onToggle={() => dispatch(toggleSidebar())}
      onSelect={handleSelectItem}
    >
      <SideNav.Toggle />
      <SideNav.Nav selected={selected}>
        {expanded && (
          <NavItem
            eventKey={AuthenticatedRoutes.Me}
            className={['profile', isSelected(AuthenticatedRoutes.Me) && 'selected']}
          >
            <div className="text-center my-1">
              <Link
                to={AuthenticatedRoutes.Me}
                onClick={() => handleSelectItem(AuthenticatedRoutes.Me)}
                className="text-decoration-none"
              >
                {
                  UserRole.Guest === props.user.role ? (
                    <Image src="/assets/graduate-support-dark-theme.svg" height={72} />
                  ) : (
                    <Image src="/assets/graduate-dark-theme.svg" height={72} />
                  )
                }
              </Link>
            </div>
            <div className="text-center my-1">
              <Link
                to={AuthenticatedRoutes.Me}
                onClick={() => handleSelectItem(AuthenticatedRoutes.Me)}
                className="text-decoration-none text-light text-uppercase small"
              >
                {props.user.firstName} {props.user.lastName}
              </Link>
            </div>
          </NavItem>
        )}
        <NavItem
          eventKey={AuthenticatedRoutesUser.Dashboard}
          className={isSelected(AuthenticatedRoutesUser.Dashboard) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesUser.Dashboard}
              onClick={() => handleSelectItem(AuthenticatedRoutesUser.Dashboard)}
              className="text-decoration-none"
            >
              <FaHome size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesUser.Dashboard}
              onClick={() => handleSelectItem(AuthenticatedRoutesUser.Dashboard)}
              className="text-decoration-none"
            >
              Home
            </Link>
          </NavText>
        </NavItem>
        <NavItem
          eventKey={AuthenticatedRoutesUser.FavoritesCourses}
          className={isSelected(AuthenticatedRoutesUser.FavoritesCourses) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesUser.FavoritesCourses}
              onClick={() => handleSelectItem(AuthenticatedRoutesUser.FavoritesCourses)}
              className="text-decoration-none"
            >
              <MdBookmarks size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesUser.FavoritesCourses}
              onClick={() => handleSelectItem(AuthenticatedRoutesUser.FavoritesCourses)}
              className="text-decoration-none"
            >
              Corsi d'interesse
            </Link>
          </NavText>
        </NavItem>
        <NavItem
          eventKey={AuthenticatedRoutesUser.Courses}
          className={isSelected(AuthenticatedRoutesUser.Courses) && 'selected'}
        >
          <NavIcon
            onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthenticatedRoutesUser.Courses)}
          >
            <FaUniversity size={32} />
          </NavIcon>
          <NavText
            onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthenticatedRoutesUser.Courses)}
          >
            Corsi
          </NavText>
          <NavItem eventKey={AuthenticatedRoutesUser.PublishedCourses} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesUser.PublishedCourses}
                onClick={() => handleSelectItem(AuthenticatedRoutesUser.PublishedCourses)}
                className="text-decoration-none text-light"
              >
                Pubblicati
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthenticatedRoutesUser.MyCourses} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesUser.MyCourses}
                onClick={() => handleSelectItem(AuthenticatedRoutesUser.MyCourses)}
                className="text-decoration-none text-light"
              >
                Tutti i miei corsi
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthenticatedRoutesUser.ScheduledCourses} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesUser.ScheduledCourses}
                onClick={() => handleSelectItem(AuthenticatedRoutesUser.ScheduledCourses)}
                className="text-decoration-none text-light"
              >
                Corsi a cui parteciperò
              </Link>
            </NavText>
          </NavItem>
        </NavItem>
        <NavItem
          eventKey={AuthenticatedRoutesUser.TrainingCredits}
          className={isSelected(AuthenticatedRoutesUser.TrainingCredits) && 'selected'}
        >
          <NavIcon
            onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthenticatedRoutesUser.TrainingCredits)}
          >
            <FaAward size={32} />
          </NavIcon>
          <NavText
            onClick={(e: React.MouseEvent<HTMLElement>) => handleSelectParent(e, AuthenticatedRoutesUser.TrainingCredits)}
          >
            Crediti formativi
          </NavText>
          <NavItem eventKey={AuthenticatedRoutesUser.TrainingCreditsStatus} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesUser.TrainingCreditsStatus}
                onClick={() => handleSelectItem(AuthenticatedRoutesUser.TrainingCreditsStatus)}
                className="text-decoration-none text-light"
              >
                Status
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthenticatedRoutesUser.TrainingCreditsRequests} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesUser.TrainingCreditsRequests}
                onClick={() => handleSelectItem(AuthenticatedRoutesUser.TrainingCreditsRequests)}
                className="text-decoration-none text-light"
              >
                Certificazioni / Esoneri
              </Link>
            </NavText>
          </NavItem>
          <NavItem eventKey={AuthenticatedRoutesUser.TrainingCreditsNewRequest} style={{ marginLeft: '40px' }}>
            <NavText>
              <Link
                to={AuthenticatedRoutesUser.TrainingCreditsNewRequest}
                onClick={() => handleSelectItem(AuthenticatedRoutesUser.TrainingCreditsNewRequest)}
                className="text-decoration-none text-light"
              >
                Crea nuova richiesta
              </Link>
            </NavText>
          </NavItem>
        </NavItem>
      </SideNav.Nav>
      <SideNav.Nav componentClass="footer">
        <NavItem
          eventKey={AuthenticatedRoutesUser.Faq}
          className={isSelected(AuthenticatedRoutesUser.Faq) && 'selected'}
        >
          <NavIcon>
            <Link
              to={AuthenticatedRoutesUser.Faq}
              onClick={() => handleSelectItem(AuthenticatedRoutesUser.Faq)}
              className="text-decoration-none"
            >
              <MdHelp size={32} />
            </Link>
          </NavIcon>
          <NavText>
            <Link
              to={AuthenticatedRoutesUser.Faq}
              onClick={() => handleSelectItem(AuthenticatedRoutesUser.Faq)}
              className="text-decoration-none"
            >
              Supporto
            </Link>
          </NavText>
        </NavItem>
        <NavItem onClick={handleLogout}>
          <NavIcon>
            <MdLogout size={32} />
          </NavIcon>
          <NavText>Esci</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
};

export default SidebarUser;
