import { Formik } from 'formik';
import { FC, Fragment, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { UnauthenticatedRoutes } from '../enums/routes.enum';
import { IResetPasswordThunkPayload, reset } from '../redux/slices/auth.slice';
import { AppDispatch } from '../redux/store';

interface IFormValue extends IResetPasswordThunkPayload { }

const initialValues: IFormValue = {
  password: '',
  token: ''
};

interface IProps {
  verticalSpacing: number;
  token?: string;
}

export const ResetPasswordForm: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true)

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required(t('forgot-password.form.errors.fieldRequired'))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Deve contenere 8 caratteri, uno maiuscolo, uno minuscolo, un numero e un carattere speciale")
      .max(15, "Lunghezza massima 15 carattri")
      .min(8, "Lunghezza minima 8 carattri"),
    newPasswordConfirmation: yup
      .string()
      .required(t('forgot-password.form.errors.fieldRequired'))
      .oneOf([yup.ref("newPassword"), null], "Le password scelte non coincidono")
  });

  const handleSubmit = (value: IFormValue) => {
    setLoading(true);

    const val = { password: value.password, token: props.token } as IResetPasswordThunkPayload;

    dispatch(reset(val as IResetPasswordThunkPayload))
      .unwrap()
      .then(() => {
        setShowForm(false);
        setLoading(false);
        setTimeout(() => navigate(UnauthenticatedRoutes.Login), 3000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} show={showForm}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className={`py-${props.verticalSpacing}`}>
              <FloatingLabel
                controlId="newPassword"
                label="Nuova password"
                className="px-1"
              >
                <Form.Control
                  type="password"
                  placeholder="Password"
                  disabled={isLoading}
                  value=""
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Row>
            <Row className={`py-${props.verticalSpacing}`}>
              <FloatingLabel
                controlId="floatingPassword"
                label="Conferma password"
                className="px-1"
              >
                <Form.Control
                  type="password"
                  placeholder="Conferma password"
                  disabled={isLoading}
                  value=""
                  onChange={handleChange}

                />
              </FloatingLabel>
            </Row>
            <Row className={`py-${props.verticalSpacing}`}>
              <Col className="text-center">
                <Button type="submit" variant="primary" disabled={isLoading}>
                  {isLoading ? (
                    <Fragment>
                      <Spinner as="span" animation="border" size="sm" role="status" />
                      <span className="ms-1">{t('forgot-password.form.submitting')}</span>
                      <span>...</span>
                    </Fragment>
                  ) : (
                    <span>Reset</span>
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {/*       
      <Modal show={showModal} onHide={handleClose} animation={false} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <GiConfirmed style={styleIcon} /> Password dimenticata
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Presto riceverai un'e-mail che ti consentirà di reimpostare la tua password. Assicurati di controllare la cartella spam e il cestino se non ricevi la nostra e-mail.</Modal.Body>
        <ModalFooter>
          <Button variant="primary" href="/login">Torna al login</Button>
        </ModalFooter>
      </Modal>
      */}
    </Fragment>
  );
};

export default ResetPasswordForm;
