import { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CertificationList from '../../components/admin/CertificationList';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { getCompany } from '../../redux/slices/company.slice';
import { getMetadataCertification, getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';
import { FaInfoCircle } from 'react-icons/fa';
import { Button, Spinner } from 'react-bootstrap';
import { b64toBlob } from '../../utils/attachment-parser';
import { format } from 'date-fns';
import { getCertificationsExport, ICertificationsExportFulfilledPayload } from '../../redux/slices/certifications.slice';

const AdminCertifications: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCompany({}));
    //dispatch(getCompanies({}));
    //dispatch(getPartners({}));
    //dispatch(getCompaniesOrder({}));
    //dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
    dispatch(getMetadataCertification({}));
  }, [dispatch]);

  const handleExport = () => {
    setIsLoading(true);
    dispatch(getCertificationsExport({}))
      .unwrap()
      .then((e) => {
        const payload = e as unknown as ICertificationsExportFulfilledPayload;
        const div = document.getElementById("divAdminExportCertificationsCSV");
        if (div) {
          b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
            const url = window.URL.createObjectURL(blobresult);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', format(new Date(), 'dd-MM-yyyy_HHmm_') + payload.attachment.filename);
            div.appendChild(link);
            link.click();
            link.remove();
          });
        }
      })
      .catch((e) => {
        // console.log("KO", e);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Fragment>
      <PageTitle content="Certificazioni / Esoneri">
        <a href="/assets/tutorial/O_ElencoCertificazioni.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Lista completa">
        <Button onClick={handleExport}>
          {isLoading ? (
            <Fragment>
              <Spinner as="span" animation="border" size="sm" role="status" />
              <span className="ms-1">Attendere</span>
              <span>...</span>
            </Fragment>
          ) : (
            <span>Esporta in .CSV</span>
          )}
        </Button>
        <div id="divAdminExportCertificationsCSV" className="d-none"></div>
      </PageSubtitle>
      <PageParagraph content="" />
      <CertificationList />
    </Fragment>
  );
};

export default AdminCertifications;