import { FC, Fragment, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IUser } from '../entities/user.entity';
import { UserRole } from '../enums/user-roles.enum';
import { RootState } from '../redux/store';
import LayoutMaster from './LayoutMaster';
import LayoutSupervisor from './LayoutSupervisor';
import LayoutManager from './LayoutManager';
import LayoutAdmin from './LayoutAdmin';
import LayoutPartner from './LayoutPartner';
import LayoutUser from './LayoutUser';
import { maintenanceModeUrl, webResourceExists } from '../utils/get-base-64.helper';

interface IProp { }

const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const Layout: FC<IProp> = (props) => {
  const user: IUser | null = useSelector((s: RootState) => (s.auth.user?.value as IUser) || null);
  const expire: string | null = useSelector((s: RootState) => (s.auth.user?.expire as string) || null);
  
  const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean>(false);
  
  useInterval(() => webResourceExists(maintenanceModeUrl).then((res) => setIsMaintenanceMode(res)).catch(() => setIsMaintenanceMode(false)), 1000 * 60 * 3);

  return (
    <Fragment>
      {user ? (
        <Fragment>
          {
            {
              [UserRole.Master]: (
                <LayoutMaster user={user} expire={expire} isMaintenanceMode={isMaintenanceMode}>
                  {props.children}
                </LayoutMaster>
              ),
              [UserRole.Supervisor]: (
                <LayoutSupervisor user={user} expire={expire} isMaintenanceMode={isMaintenanceMode}>
                  {props.children}
                </LayoutSupervisor>
              ),
              [UserRole.Manager]: (
                <LayoutManager user={user} expire={expire} isMaintenanceMode={isMaintenanceMode}>
                  {props.children}
                </LayoutManager>
              ),
              [UserRole.Admin]: (
                <LayoutAdmin user={user} expire={expire} isMaintenanceMode={isMaintenanceMode}>
                  {props.children}
                </LayoutAdmin>
              ),
              [UserRole.Partner]: (
                <LayoutPartner user={user} expire={expire} isMaintenanceMode={isMaintenanceMode}>
                  {props.children}
                </LayoutPartner>
              ),
              [UserRole.User]: (
                <LayoutUser user={user} expire={expire} isMaintenanceMode={isMaintenanceMode}>
                  {props.children}
                </LayoutUser>
              ),
              [UserRole.Guest]: (
                <LayoutUser user={user} expire={expire} isMaintenanceMode={isMaintenanceMode}>
                  {props.children}
                </LayoutUser>
              ),
            }[user.role]
          }
        </Fragment>
      ) : (
        <main className="d-flex no-auth">{props.children}</main>
      )}
    </Fragment>
  );
};

export default Layout;