import { FC, Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Professional from '../../components/manager/Professional';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { IProfessionalEntity } from '../../entities/professional.entity';
import { getCompaniesOrder } from '../../redux/slices/companies.slice';
import { getMetadataCertification, getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch, RootState } from '../../redux/store';

const ManagerProfessional: FC = () => {
  const { cf } = useParams<{ cf: string }>();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    //dispatch(getCompany({}));
    //dispatch(getCompanies({}));
    //dispatch(getPartners({}));
    dispatch(getCompaniesOrder({}));
    //dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
    //dispatch(getMetadataTeacher({}));
    dispatch(getMetadataCertification({}));
  }, [dispatch]);

  const professional: IProfessionalEntity | null = useSelector((s: RootState) => (s.professional.value));

  return (
    <Fragment>
      <PageTitle content="Professionista">
        <a href="/assets/tutorial/O_ElencoProfessionisti.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content={`${professional?.cognome.toUpperCase()} ${professional?.nome.toUpperCase()}`} />
      <PageParagraph content="" />
      {
        cf && cf.length === 16 ? (
          <Container as="section" fluid>
            <Row>
              <Col>
                <Professional codiceFiscale={cf} />
              </Col>
            </Row>
          </Container>
        ) : (
          <Fragment />
        )
      }
    </Fragment>
  );
};

export default ManagerProfessional;