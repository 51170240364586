import { FC, Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, FormControl, InputGroup, Pagination, Row, Spinner } from 'react-bootstrap';
import { Search, X } from 'react-bootstrap-icons';
import { BsGridFill, BsListUl } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IRejectResponse } from '../../api/api.interface';
import { ICourseRaw } from '../../entities/course.entity';
import { IMetadataOption } from '../../entities/metadata.entity';
import { AuthenticatedRoutesAdmin } from '../../enums/routes.enum';
import { getCourses, IGetCoursesFilter, IGetCoursesPagination, IGetCoursesSorting, IGetCoursesThunkPayload } from '../../redux/slices/courses.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { AdminCourseListOrderBy } from '../../utils/course-default';
import { parseRawToICourse } from '../../utils/course-parser';
import CourseListItem from '../shared/CourseListItem';
import AreaFormativa from '../shared/dropdown/course/AreaFormativa';
import Erogazione from '../shared/dropdown/course/Erogazione';
import Tipologia from '../shared/dropdown/course/Tipologia';
import Triennio from '../shared/dropdown/course/Triennio';
import PaginationLimit from '../shared/dropdown/list/PaginationLimit';
import Sorting from '../shared/dropdown/list/Sorting';
import MeseAnno from '../shared/dropdown/MeseAnno';
import PageSelect from '../shared/dropdown/list/PageSelect';
import OrdineProvinciale from '../shared/dropdown/OrdineProvinciale';
import { ICompanyEntity } from '../../entities/company.entity';
import EventoGratuito from '../shared/dropdown/course/EventoGratuito';

interface IPagination extends IGetCoursesPagination { }
interface IFilter extends IGetCoursesFilter { }
interface ISorting extends IGetCoursesSorting { }

interface IProps {
  statoPubblicazione: Array<number>;
  active: boolean;
  full: boolean;
}

const OwnCourseList: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const error = useSelector((s: RootState) => s.courses.error as IRejectResponse);
  const courses: Array<ICourseRaw> = useSelector((s: RootState) => s.courses.value);
  const countTotalCourses: number = useSelector((s: RootState) => s.courses.total);
  
  const [isLoading, setLoading] = useState(false);
  const [isGridView, setIsGridView] = useState<boolean>(false);
  const [filter, setFilter] = useState<IFilter>({ annoInizio: 0, meseInizio: 0, areaFormativa: 0, erogazione: 0, supplier: 0, tipoAzienda: 0, text: '', tipologia: 0, statoPubblicazione: props.statoPubblicazione, triennio: 0, full : props.full ? 1 : 0, eventoGratuito: 0 });
  const [pagination, setPagination] = useState<IPagination>({ page: 1, limit: 25 });
  const [sorting, setSorting] = useState<ISorting>({ orderby: [AdminCourseListOrderBy[0].nome], ordertype: true });
  const [textAreaSearch, setTextareaSearch] = useState('');

  useEffect(() => {
    if (props.active) {
      setLoading(true);
      dispatch(getCourses({ filter: filter, pagination: pagination, sorting: sorting } as IGetCoursesThunkPayload))
        .unwrap()
        .finally(() => setLoading(false));
    }
  }, [dispatch, filter, pagination, sorting, props.active]);

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setTextareaSearch(text);
  };

  const handleFilterByText = () => {
    setFilter({ ...filter, text: textAreaSearch });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleClearFilterByText = () => {
    setTextareaSearch('');
    setFilter({ ...filter, text: '' });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const toggleGridView = () => {
    setIsGridView(!isGridView);
  }

  const handleFilterByOrdine = (option: ICompanyEntity | null) => {
    setFilter({ ...filter, supplier: option ? option.idAzienda : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByTriennio = (imet: IMetadataOption | null) => {
    setFilter({ ...filter, triennio: imet ? imet.id : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByAnno = (option: number | null) => {
    setFilter({ ...filter, annoInizio: option ? option : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByMese = (option: number | null) => {
    setFilter({ ...filter, meseInizio: option ? option : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByErogazione = (option: IMetadataOption | null) => {
    setFilter({ ...filter, erogazione: option ? option.id : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByTipologia = (option: IMetadataOption | null) => {
    setFilter({ ...filter, tipologia: option ? option.id : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByEventoGratuito = (option: number | null) => {
    setFilter({ ...filter, eventoGratuito: option ? option : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleFilterByCategoria = (option: IMetadataOption | null) => {
    setFilter({ ...filter, areaFormativa: option ? option.id : 0 });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleSortingOrderBy = (ordby: Array<IMetadataOption> | null) => {
    setSorting({ ...sorting, orderby: ordby ? ordby.map(oby => oby.nome) : [] });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handleSortingOrderType = (type: boolean) => {
    setSorting({ ...sorting, ordertype: type });
    if (pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    }
  };

  const handlePaginationLimit = (limit: number) => {
    setPagination({ page: 1, limit: limit });
  };

  const handlePaginationLoad = (pag: number) => {
    setPagination({ ...pagination, page: pag });
  };

  const handlePaginationLoadMore = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const handlePaginationLoadMinus = () => {
    const previuosPage = pagination.page - 1;
    if (previuosPage === 0) return;
    setPagination({ ...pagination, page: pagination.page - 1 });
  };

  const handleClickCourseItem = (idCorso: number) => {
    let route = "";
    if (props.full) {
      route = AuthenticatedRoutesAdmin.CourseOther.toString();
    } else {
      route = AuthenticatedRoutesAdmin.Course.toString();
    }    
    route = route.replace(':id', idCorso.toString());
    navigate(route);
  };

  return (
    <Fragment>
      <Container fluid as="section" className="m-0 p-0">
        <Row className="bg-light">
          <Col className="my-3">
            <InputGroup className="w-100">
              <FormControl placeholder="Cerca nella lista digitando: titolo e premi INVIO..." id="idTextSearchOwnCourses"
                onChange={handleChangeText}
                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleFilterByText();
                  }
                }} />
              <InputGroup.Text title="Pulisci filtro ricerca" className="c-pointer"
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  const element = document.getElementById("idTextSearchOwnCourses") as HTMLInputElement;
                  if (element) {
                    element.value = '';
                  }
                  handleClearFilterByText();
                }}><X size={20} /></InputGroup.Text>
              <InputGroup.Text title="Inizia la ricerca" className="c-pointer"
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  handleFilterByText();
                }}><Search size={20} /></InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
        <Row className="bg-light">
          <Col className="mb-3">
            <InputGroup className="d-flex justify-content-end">
              <Button onClick={() => toggleGridView()} variant="outline-primary" title={`Passa alla visualizzazione a ${isGridView ? 'lista' : 'griglia'} `}>
                { isGridView ? (<BsListUl />) : (<BsGridFill />) }
              </Button>
              { props.full ? (<OrdineProvinciale onSelect={handleFilterByOrdine} />) : (<Fragment />)}
              { filter.annoInizio > 0 ? (<Fragment />) : (<Triennio onSelect={handleFilterByTriennio} />)}
              { filter.triennio > 0 ? (<Fragment />) : (<MeseAnno onSelectMonth={handleFilterByMese} onSelectYear={handleFilterByAnno} />)}
              <EventoGratuito onSelect={handleFilterByEventoGratuito} />
              <Erogazione onSelect={handleFilterByErogazione} />
              <Tipologia onSelect={handleFilterByTipologia} />
              <AreaFormativa onSelect={handleFilterByCategoria} />
            </InputGroup>
          </Col>
        </Row>
        <Row className="bg-light mb-1">
          <Col className="mx-1 mb-3">
          {(courses.length > 0 ? "corsi " + (1 + pagination.limit * (pagination.page-1)) + "-" + (courses.length + pagination.limit * (pagination.page-1)) + 
                                (courses.length >= countTotalCourses ? "" : " di " + countTotalCourses) : "0 corsi TOTALI")}
          </Col>
          <Col className="mb-3">
            <InputGroup className="d-flex justify-content-end">
              <PageSelect defaultSelectedId={1} totPagine={Math.ceil(countTotalCourses / pagination.limit)} onSelect={handlePaginationLoad} />
              <Sorting initSortingOptions={AdminCourseListOrderBy}
                onSelectOrderBy={handleSortingOrderBy} onSelectOrderType={handleSortingOrderType}
                defaultOrderBy={[AdminCourseListOrderBy[0]]} defaultOrderType={true} />
              <PaginationLimit enableUnlimit={false} onSelect={handlePaginationLimit} />
            </InputGroup>
          </Col>
        </Row>

        {error ? (
          <Row>
            <Col className="text-center">
              <p className="text-danger">{error.error}</p>
            </Col>
          </Row>
        ) : (
          courses.length ? (
            <section className={`d-flex ${isGridView ? 'flex-wrap align-content-stretch' : 'flex-column'} `}>
              {courses.map((course: ICourseRaw, index: number) => (
                <div key={'idx' + index + 'id' + course.id + (isGridView ? 'grid' : 'row')} className={`m-1 ${isGridView ? 'd-flex' : ''}`}>
                  <CourseListItem course={parseRawToICourse(course)} isGridView={isGridView} onClick={handleClickCourseItem} />
                </div>
              ))}
            </section>
          ) : (
            <Fragment>
              {(pagination.page === 1) ? (
                <Row className="py-3">
                  <Col className="text-center">
                    <p className="text-dark">Nessun risultato</p>
                  </Col>
                </Row>
              ) : (
                <Row className="py-3">
                  <Col className="text-center">
                    <p className="text-dark">Non ci sono altri record da consultare</p>
                  </Col>
                </Row>
              )}
            </Fragment>
          )
        )}

        {isLoading ? (
          <Row>
            <Col className="text-center">
              <Spinner as="span" animation="border" size="sm" role="status" />
              <p>
                <span>Attendere</span>
                <span>...</span>
              </p>
            </Col>
          </Row>
        ) : (
          <Row className="py-2">
            <Col className="text-center">
              <Pagination className="d-flex justify-content-center">
                <Pagination.First onClick={() => handlePaginationLoad(1)} disabled={(pagination.page - 1) === 0}>Prima pagina</Pagination.First>
                <Pagination.Prev key={"idPaginationItemPrev"} onClick={handlePaginationLoadMinus} disabled={(pagination.page - 1) === 0}>Precedente</Pagination.Prev>
                <Pagination.Item key={pagination.page} onClick={() => handlePaginationLoad(pagination.page)}>{pagination.page}</Pagination.Item>
                <Pagination.Next key={"idPaginationItemNext"} onClick={handlePaginationLoadMore} disabled={pagination.limit != courses.length}>Successiva</Pagination.Next>
                <Pagination.Last onClick={() => handlePaginationLoad(Math.ceil(countTotalCourses / pagination.limit))} disabled={pagination.limit != courses.length}>Ultima pagina</Pagination.Last>
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>
    </Fragment>
  );
};

export default OwnCourseList;