import { format } from "date-fns";
import { IAttachmentEntity } from "../entities/attachment.entity";
import { ICourseEntityRaw } from "../entities/course.entity";
import { IMetadataOption } from "../entities/metadata.entity";
import { ISubscriptionEntity } from "../entities/subscription.entity";
import { ITeacherEntity } from "../entities/teacher.entity";

export const dateFormatRaw = 'yyyy-MM-dd';

export const linkMoodleBaseUrl = 'https://moodle.awn.it/course/view.php?id=';

export const courseInit: ICourseEntityRaw = {
  id: 0,
  idCorsoRiferimento: null,
  idAziendaProponente: 0,
  mailInformazioni: '',
  titolo: '',
  dataIstanza: format(new Date(), dateFormatRaw),
  nCorso: '',
  nProtIn: '',
  dataProtIn: '',
  idAreaFormativa: 0,
  obiettivi: '',
  idErogazione: 0,
  idTipologia: 0,
  dataInizio: format(new Date(), dateFormatRaw),
  dataFine: format(new Date(), dateFormatRaw),
  flagNonSvolto: false,
  nOre: 0,
  cpfRichiesti: 0,
  cpfAssegnati: 0,
  flagConforme: true,
  codice: '',
  flagAccettaIscrizioni: true,
  flagChiudiIscrizioni: false,
  nPartecipantiMin: 0,
  nPartecipantiMax: 0,
  nPartecipantiExt: 0,
  flagEventoAperto: false,
  flagAbilitaListadattesa: false,
  idStatoPubblicazione: 1,
  idAzienda: 0,
  cancellato: false,
  moodleCourseId: '',
  valutazione: {
    idCorso: 0,
    idArticolazioneCorso: 0,
    lingua: '',
    partner: '',
    referente: '',
    nDelibera: 0,
    dataDelibera: '',
    flagValutazioneInterna: false,
    dataValutazioneInterna: '',
    idStato: 0,
    dettagliStato: '',
    flagSvolgimentoInSede: false,
    indirizzoSede: '',
    idAccessibilita: 0,
    flagConformitaStrutture: false,
    strutture: '',
    fornitura: '',
    frequenzaMin: 0,
    flagAttestatoPartecipazione: false,
    flagGiudizioPerDocente: false,
    flagGiudizioPerCorso: false,
    cpfDescrizione: '',
    dataInvioIstanza: '',
    flagValutazioneConformita: false,
    dataValutazioneConformita: '',
    nProtOut: '',
    dataProtOut: '',
  },
  modalita: {
    idCorso: 0,
    dataIscrizioneDal: '',
    dataIscrizioneAl: '',
    nPartecipantiMaxPerChiudereCorso: 0,
    nPartecipantiExtPerChiudereCorso: 0,
    descIscrizioneA: 'Iscrizione gratuita',
    accontoIscrizioneA: 0,
    saldoIscrizioneA: 0,
    idPagamentoIscrizioneA: 0,
    flagIscrizioneIntA: true,
    descIscrizioneB: '',
    accontoIscrizioneB: 0,
    saldoIscrizioneB: 0,
    idPagamentoIscrizioneB: 0,
    flagIscrizioneIntB: false,
    descIscrizioneC: '',
    accontoIscrizioneC: 0,
    saldoIscrizioneC: 0,
    idPagamentoIscrizioneC: 0,
    flagIscrizioneIntC: false,
    descIscrizioneD: '',
    accontoIscrizioneD: 0,
    saldoIscrizioneD: 0,
    idPagamentoIscrizioneD: 0,
    flagIscrizioneIntD: false,
    saldoEntroGg: 0,
    dettaglioModalitaIscrizione: '',
    flagAccettazioneClausole: false,
  },
  pubblicazione: {
    idCorso: 0,
    areaPubDescrizione: '',
    areaRisCollegamentoEsterno: '',
    areaRisDescrizione: '',
  },
  docenti: new Array<ITeacherEntity>(),
  allegati: new Array<IAttachmentEntity>(),
  iscritti: new Array<ISubscriptionEntity>(),
} as ICourseEntityRaw;

export const AdminCourseListOrderBy: Array<IMetadataOption> = [
  { id: 1, nome: 'dataInizio', descrizione: 'Data inizio' },
  { id: 2, nome: 'dataFine', descrizione: 'Data fine' },
//{ id: 3, nome: 'dataIstanza', descrizione: 'Data' },
  { id: 4, nome: 'titolo', descrizione: 'Titolo' },
  { id: 5, nome: 'codice', descrizione: 'Codice' },
  { id: 6, nome: 'cpfAssegnati', descrizione: 'CFP' },
//{ id: 7, nome: 'flagConforme', descrizione: 'Conforme' },
  { id: 8, nome: 'flagAccettaIscrizioni', descrizione: 'Accetta iscrizioni' },
  { id: 9, nome: 'flagChiudiIscrizioni', descrizione: 'Iscrizioni chiuse' },
  { id: 13, nome: 'flagEventoAperto', descrizione: 'Evento aperto' },
  { id: 10, nome: 'nPartecipantiMin', descrizione: 'Num. minimo iscrizioni' },
  { id: 11, nome: 'nPartecipantiMax', descrizione: 'Num. massimo iscrizioni' },
  { id: 12, nome: 'nPartecipantiExt', descrizione: 'Num. iscrizioni esterne' }
];

export const SharedCourseListOrderBy: Array<IMetadataOption> = [
  { id: 1, nome: 'titolo', descrizione: 'Titolo' },
  { id: 2, nome: 'dataIstanza', descrizione: 'Data' },
  { id: 3, nome: 'nCorso', descrizione: 'Numero' },
  { id: 4, nome: 'dataInizio', descrizione: 'Data inizio' },
  { id: 5, nome: 'dataFine', descrizione: 'Data fine' },
  { id: 6, nome: 'cpfAssegnati', descrizione: 'CFP' },
  { id: 7, nome: 'flagConforme', descrizione: 'Conforme' },
  { id: 8, nome: 'codice', descrizione: 'Codice' },
  { id: 9, nome: 'flagAccettaIscrizioni', descrizione: 'Accetta iscrizioni' },
  { id: 10, nome: 'flagChiudiIscrizioni', descrizione: 'Iscrizioni chiuse' },
  { id: 11, nome: 'nPartecipantiMin', descrizione: 'Num. minimo iscrizioni' },
  { id: 12, nome: 'nPartecipantiMax', descrizione: 'Num. massimo iscrizioni' },
  { id: 13, nome: 'nPartecipantiExt', descrizione: 'Num. iscrizioni esterne' },
  { id: 14, nome: 'flagEventoAperto', descrizione: 'Evento aperto' }
];

export const SharedFavoritesListOrderBy: Array<IMetadataOption> = [
  { id: 1, nome: 'titolo', descrizione: 'Titolo' },
  { id: 2, nome: 'statoInteresse', descrizione: 'Stato' },
  // { id: 3, nome: 'nCorso', descrizione: 'Numero' },
  // { id: 4, nome: 'dataInizio', descrizione: 'Data inizio' },
];