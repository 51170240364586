import React, { FC, Fragment, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { IMetadataOption } from '../../entities/metadata.entity';
import { IProfessionalsExportStatusCfpThunkPayload, getProfessionalsExportStatusCfp, IProfessionalsExportStatusCfpFulfilledPayload } from '../../redux/slices/professionals.slice';
import { AppDispatch } from '../../redux/store';
import { b64toBlob } from '../../utils/attachment-parser';
import Triennio from './dropdown/course/Triennio';
import StatoIscrizione from './dropdown/professional/StatoIscrizione';
import StatusCfpOk from './dropdown/professional/StatusCfpOk';

const ProfessionalsExportStatus: FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterByTriennio, setFilterByTriennio] = useState<IMetadataOption | null>(null);
    const [filterByStatoIscrizione, setFilterByStatoIscrizione] = useState<string | null>(null);
    const [filterByStatusCfpOk, setFilterByStatusCfpOk] = useState<boolean | null>(null);

    const handleTriennioClick = (option: IMetadataOption | null) => {
        setFilterByTriennio(option);
    };

    const handleStatoIscrizioneClick = (option: string | null) => {
        setFilterByStatoIscrizione(option);
    };

    const handleStatusCfpOkClick = (option: boolean | null) => {
        setFilterByStatusCfpOk(option);
    };
    
    const handleDownloadProfessionalsStatusCfp = () => {
        setIsLoading(true);
        dispatch(getProfessionalsExportStatusCfp({ idTriennio: filterByTriennio?.id, idStatoIscrizione: filterByStatoIscrizione, isStatusCfpOk: filterByStatusCfpOk} as IProfessionalsExportStatusCfpThunkPayload))
            .unwrap()
            .then((e) => {
                const payload = e as unknown as IProfessionalsExportStatusCfpFulfilledPayload;
                const div = document.getElementById("divDownloadProfessionalsStatusCfp");
                if (div) {
                    b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
                        const url = window.URL.createObjectURL(blobresult);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', payload.attachment.filename);
                        div.appendChild(link);
                        link.click();
                        link.remove();
                    });
                }
            })
            .catch((e) => {
                // console.log("KO", e);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Fragment>
            <Container fluid as="section">
                <Row>
                    <Col>
                        <Triennio onSelect={handleTriennioClick} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="d-flex flex-row">
                        <StatoIscrizione onSelect={handleStatoIscrizioneClick} />
                        <StatusCfpOk onSelect={handleStatusCfpOkClick} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Button variant="primary" className="w-100" onClick={(e: React.SyntheticEvent) => {
                                e.preventDefault();
                                handleDownloadProfessionalsStatusCfp();
                            }} disabled={isLoading}>
                            {   
                                isLoading ? (
                                    <div className="text-center">
                                        <Spinner as="span" animation="border" size="sm" role="status" /> <span>Attendere ...</span>
                                    </div>
                                ) : (
                                    <span>Scarica .CSV</span>
                                )
                            }
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="text-center">
                        <p className="text-secondary small">Scegli il triennio o lascia vuoto per ottenere tutti i trienni già trascorsi. <br/>
                            Lo status formativo tiene conto del ravvedimento operoso. <br/>
                            Si fa presente che il triennio in corso non è disponibile.</p>
                    </Col>
                </Row>
            </Container>
            <div id="divDownloadProfessionalsStatusCfp" className="d-none"></div>
        </Fragment>
    );
};

export default ProfessionalsExportStatus;