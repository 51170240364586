import { format } from 'date-fns';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Badge, Button, Col, Container, FormControl, InputGroup, Pagination, Row } from "react-bootstrap";
import { Search, X } from 'react-bootstrap-icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from "react-redux";
import { ICompanyEntity } from "../../../entities/company.entity";
import { IMetadataOption } from "../../../entities/metadata.entity";
import { IProfessionalEntity } from "../../../entities/professional.entity";
import { getProfessionals, IGetProfessionalsFilter, IGetProfessionalsPagination, IGetProfessionalsSorting } from "../../../redux/slices/professionals.slice";
import { AppDispatch, RootState } from "../../../redux/store";
import { AdminProfessionalListOrderBy } from "../../../utils/professionals-default";
import PaginationLimit from "../dropdown/list/PaginationLimit";
import Sorting from "../dropdown/list/Sorting";
import { getOrdineProvinciale } from "../dropdown/OrdineProvinciale";
import Esterno from "../dropdown/professional/Esterno";
import StatoIscrizione from "../dropdown/professional/StatoIscrizione";
import PageSelect from '../dropdown/list/PageSelect';

interface IProps {
    onSelect?: (professional: IProfessionalEntity, ext: boolean) => void;
    showDetail: boolean;
    disableFilterByExt: boolean;
};

const SearchProfessionalList: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const professionals: Array<IProfessionalEntity> = useSelector((s: RootState) => s.professionals.value);
    const countTotalProfessionals: number = useSelector((s: RootState) => s.professionals.total);
    const companiesOrders: Array<ICompanyEntity> = useSelector((s: RootState) => s.companies.orders);
    const company: ICompanyEntity | null = useSelector((s: RootState) => s.company.value);

    const [filter, setFilter] = useState<IGetProfessionalsFilter>({ text: '', esterno: props.disableFilterByExt ? 0 : 1, statoIscrizione: 'iscritto' });
    const [pagination, setPagination] = useState<IGetProfessionalsPagination>({ page: 1, limit: 10 });
    const [sorting, setSorting] = useState<IGetProfessionalsSorting>({ orderby: Array<string>(), ordertype: false });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [textAreaSearch, setTextAreaSearch] = useState<string>('');

    const columns: TableColumn<IProfessionalEntity>[] = [
        {
            cell: (row, index, column, id) => {
                return (<Button variant="primary" size="sm" className={`my-1`} onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    if (props.onSelect) {
                        let ext = (filter.esterno === 1 ? true : false);
                        if(company != null) {
                            ext = company.idAzienda != row.ordine;
                        }
                        props.onSelect(row, ext);
                    }
                }} >seleziona</Button>);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Professionista',
            selector: (row: IProfessionalEntity) => (row.cognome ? row.cognome.toUpperCase() : '') + ' ' + row.nome,
        },
        {
            id: 'codiceFiscale',
            name: 'Codice Fiscale',
            selector: (row: IProfessionalEntity) => row.codiceFiscale,
        },
        {
            id: 'status',
            name: 'Stato',
            selector: (row: IProfessionalEntity) => row.status ? row.status : "",
            omit: !props.showDetail || filter.statoIscrizione != '',
            cell: (row: IProfessionalEntity) => (
                row.status ? (
                    row.status.toLowerCase() === "iscritto" ? (
                        <Badge bg="success">{row.status}</Badge>
                    ) : (
                        row.status.toLowerCase() === "sospeso" ? (
                            <Badge bg="warning">{row.status}</Badge>
                        ) : (
                            <Badge bg="danger">{row.status}</Badge>
                        )
                    )
                ) : (
                    <Fragment />
                )
            ),
        },
        {
            id: 'email',
            name: 'Email',
            omit: !props.showDetail,
            selector: (row: IProfessionalEntity) => row.email ? row.email.toLowerCase() : '',
        },
        {
            id: 'pec',
            name: 'pec',
            omit: !props.showDetail,
            selector: (row: IProfessionalEntity) => row.pec ? row.pec.toLowerCase() : '',
        },
        {
            id: 'ordine',
            name: 'Ordine',
            selector: (row: IProfessionalEntity) => getOrdineProvinciale(row.ordine, companiesOrders),
        },
        {
            id: 'dataPrimaIscrizione',
            name: 'Data prima iscrizione',
            omit: !props.showDetail,
            selector: (row: IProfessionalEntity) => {
                if (!row.dataPrimaIscrizione) return '';
                return format(new Date(row.dataPrimaIscrizione), 'dd/MM/yyyy');
            },
        },
    ] as TableColumn<IProfessionalEntity>[];

    const handleFilterByEsterno = (esterno: boolean) => {
        setFilter({ ...filter, esterno: esterno ? 1 : 0 });
        if (pagination.page !== 1) {
            setPagination({ ...pagination, page: 1 });
        }
    };

    const handleFilterByStatoIscrizione = (stato: string | null) => {
        setFilter({ ...filter, statoIscrizione: stato ? stato : "" });
        if (pagination.page !== 1) {
            setPagination({ ...pagination, page: 1 });
        }
    };

    const handleSortingOrderBy = (ordby: Array<IMetadataOption> | null) => {
        setSorting({ ...sorting, orderby: ordby ? ordby.map(oby => oby.nome) : [] });
        if (pagination.page !== 1) {
            setPagination({ ...pagination, page: 1 });
        }
    };

    const handleSortingOrderType = (type: boolean) => {
        setSorting({ ...sorting, ordertype: type });
        if (pagination.page !== 1) {
            setPagination({ ...pagination, page: 1 });
        }
    };

    const handlePaginationLimit = (limit: number) => {
        setPagination({ page: 1, limit: limit });
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(getProfessionals({ filter: filter, pagination: pagination, sorting: sorting }))
            .unwrap()
            .finally(() => setIsLoading(false));
    }, [dispatch, filter, pagination, sorting]);

    const handlePaginationLoad = (pag: number) => {
        setPagination({ ...pagination, page: pag });
    };

    const handlePaginationLoadMore = () => {
        setPagination({ ...pagination, page: pagination.page + 1 });
    };

    const handlePaginationLoadMinus = () => {
        const previuosPage = pagination.page - 1;
        if (previuosPage === 0) return;
        setPagination({ ...pagination, page: pagination.page - 1 });
    };

    const handleFilterByText = () => {
        setFilter({ ...filter, text: textAreaSearch });
        if (pagination.page !== 1) {
            setPagination({ ...pagination, page: 1 });
        }
    };

    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setTextAreaSearch(text);
    };

    const handleClearFilterByText = () => {
        setTextAreaSearch('');
        setFilter({ ...filter, text: '' });
        if (pagination.page !== 1) {
            setPagination({ ...pagination, page: 1 });
        }
    };

    return (
        <Fragment>
            <Container fluid as="section" className="m-0 p-0">
                <Row className="p-2 bg-light">
                    <Col>
                        <InputGroup>
                            <FormControl placeholder="Cerca digitando: codice fiscale, cognome e/o nome, email e premi INVIO..." id="idTextSearch"
                                onChange={handleChangeText}
                                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        handleFilterByText();
                                    }
                                }} />
                            <InputGroup.Text title="Pulisci filtro ricerca" className="c-pointer"
                                onClick={(event: React.MouseEvent) => {
                                    event.preventDefault();
                                    const element = document.getElementById("idTextSearch") as HTMLInputElement;
                                    if (element) {
                                        element.value = '';
                                    }
                                    handleClearFilterByText();
                                }}><X size={20} /></InputGroup.Text>
                            <InputGroup.Text title="Inizia la ricerca" className="c-pointer"
                                onClick={(event: React.MouseEvent) => {
                                    event.preventDefault();
                                    handleFilterByText();
                                }}><Search size={20} /></InputGroup.Text>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="px-2 pt-1 pb-2 bg-light">
                    <Col>
                        <InputGroup className="d-flex justify-content-end">
                            <StatoIscrizione onSelect={handleFilterByStatoIscrizione} defaultSelectedId={'iscritto'} />
                            <div className={`${props.disableFilterByExt ? 'd-none' : ''}`}>
                                <Esterno onSelect={handleFilterByEsterno} defaultSelectedId={!props.disableFilterByExt} />
                            </div>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="px-2 pt-1 pb-2 bg-light mb-1">
                    <Col className="my-1">
                        {(professionals.length > 0 ? "professionisti " + (1 + pagination.limit * (pagination.page-1)) + "-" + (professionals.length + pagination.limit * (pagination.page-1)) + 
                                (professionals.length >= countTotalProfessionals ? "" : " di " + countTotalProfessionals) : "0 professionisti TOTALI")}
                    </Col>
                    <Col>
                        <InputGroup className="d-flex justify-content-end">
                            <PageSelect defaultSelectedId={1} totPagine={Math.ceil(countTotalProfessionals / pagination.limit)} onSelect={handlePaginationLoad} />
                            <Sorting initSortingOptions={AdminProfessionalListOrderBy} onSelectOrderBy={handleSortingOrderBy} onSelectOrderType={handleSortingOrderType} />
                            <PaginationLimit enableUnlimit={false} onSelect={handlePaginationLimit} defaultSelectedId={10} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable columns={columns} data={professionals}
                            responsive striped dense
                            noDataComponent="Nessun risultato"
                            progressPending={isLoading} />
                    </Col>
                </Row>
                <Row className="py-2">
                    <Col className="text-center">
                        <Pagination className="d-flex justify-content-center">
                            <Pagination.First onClick={() => handlePaginationLoad(1)} disabled={(pagination.page - 1) === 0}>Prima pagina</Pagination.First>  
                            <Pagination.Prev key={"idPaginationItemPrev"} onClick={handlePaginationLoadMinus} disabled={(pagination.page - 1) === 0}>Precedente</Pagination.Prev>
                            <Pagination.Item key={pagination.page} onClick={() => handlePaginationLoad(pagination.page)}>{pagination.page}</Pagination.Item>
                            <Pagination.Next key={"idPaginationItemNext"} onClick={handlePaginationLoadMore} disabled={pagination.limit != professionals.length}>Successiva</Pagination.Next>
                            <Pagination.Last onClick={() => handlePaginationLoad(Math.ceil(countTotalProfessionals / pagination.limit))} disabled={pagination.limit != professionals.length}>Ultima pagina</Pagination.Last>
                        </Pagination>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default SearchProfessionalList;