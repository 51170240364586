import { FC, useEffect, useState } from 'react';
import { Col, Container, Row, Card, InputGroup, Alert, Button, Modal } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { IRejectResponse } from '../../api/api.interface';
import { IMetadataOption } from '../../entities/metadata.entity';
import { IProfessionalStatusEntity } from '../../entities/training-credits.entity';
import { getProfessionalStatus, IProfessionalStatusThunkPayload } from '../../redux/slices/professional.slice';
import { AppDispatch, RootState } from '../../redux/store';
import TipoFormazione, { getTipoFormazione } from './dropdown/course/TipoFormazione';
import Triennio, { getTriennio } from './dropdown/course/Triennio';
import TrainingCreditsBarSummary from './TrainingCreditsBarSummary';
import { FaRegFilePdf } from 'react-icons/fa';
import ProfessionalsPdfCv from './ProfessionalsPdfCv';
import { CircleFill } from 'react-bootstrap-icons';

interface IProps {
    codiceFiscale: string;
}

const ProfessionalTrainingCredits: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [err, setErr] = useState<IRejectResponse | null>(null);
    const [filterByTrainingType, setFilterByTrainingType] = useState<boolean | null>(null);

    const [trainingCreditsDeoTOT, setTrainingCreditsDeoTOT] = useState<number>(0);
    const [trainingCreditsOrdTOT, setTrainingCreditsOrdTOT] = useState<number>(0);
    const [trainingCreditsTriennioPrecDeoTOT, setTrainingCreditsTriennioPrecDeoTOT] = useState<number>(0);
    const [trainingCreditsTriennioPrecOrdTOT, setTrainingCreditsTriennioPrecOrdTOT] = useState<number>(0);
    const [trainingCreditsDeoEsoTOT, setTrainingCreditsDeoEsoTOT] = useState<number>(0);
    const [trainingCreditsOrdEsoTOT, setTrainingCreditsOrdEsoTOT] = useState<number>(0);

    const [cfpAcquisiti, setCfpAcquisiti] = useState<number>(0);
    const [cfpDebito, setCfpDebito] = useState<number>(0);
    const [cfpDebitoDeo, setCfpDebitoDeo] = useState<number>(0);
    const [cfpDebitoOrd, setCfpDebitoOrd] = useState<number>(0);
    const [cfpCredito, setCfpCredito] = useState<number>(0);
    const [cfpAcquisitiDeo, setCfpAcquisitiDeo] = useState<number>(0);
    const [cfpAcquisitiOrd, setCfpAcquisitiOrd] = useState<number>(0);

    const [statusOkDeo, setStatusOkDeo] = useState<boolean>();
    const [statusOkOrd, setStatusOkOrd] = useState<boolean>();

    const trienni: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.trienni);
    const trainingCredits: Array<IProfessionalStatusEntity> = useSelector((s: RootState) => s.professional.trainingCredits.filter((elem: IProfessionalStatusEntity) => {
        if (filterByTrainingType === null) return true;
        if (filterByTrainingType === true && elem.deontologico == true) return true;
        if (filterByTrainingType === false && elem.deontologico == false) return true;
        return false;
    }));

    const [filterByThreeYears, setFilterByThreeYears] = useState<IMetadataOption | null>(trienni.length ? trienni[trienni.length - 1] : null);
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleShowModal = () => setShowModal(true);
    const handleHideModal = () => setShowModal(false);

    const handleThreeYearsClick = (option: IMetadataOption | null) => {
        setFilterByThreeYears(option);
    };

    const handleTrainingTypeClick = (option: boolean | null) => {
        setFilterByTrainingType(option);
    };

    const handleDeoEsoTOT = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => elem.deontologico && (elem.raggruppamento.value !== 1 && elem.raggruppamento.value !== 2 && elem.raggruppamento.value !== 3)).forEach(el => {
            tot = tot + el.crediti;
        });
        setTrainingCreditsDeoEsoTOT(tot);
    };

    const handleOrdEsoTOT = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => !elem.deontologico && (elem.raggruppamento.value !== 1 && elem.raggruppamento.value !== 2 && elem.raggruppamento.value !== 3)).forEach(el => {
            tot = tot + el.crediti;
        });
        setTrainingCreditsOrdEsoTOT(tot);
    };

    const handleDeoTOT = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => elem.deontologico && elem.raggruppamento.value !== 1).forEach(el => {
            tot = tot + el.crediti;
        });
        setTrainingCreditsDeoTOT(tot);
    };

    const handleOrdTOT = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => !elem.deontologico && elem.raggruppamento.value !== 1).forEach(el => {
            tot = tot + el.crediti;
        });
        setTrainingCreditsOrdTOT(tot);
    };

    const handleTriennioPrecOrdTOT = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => !elem.deontologico && elem.raggruppamento.value === 1).forEach(el => {
            tot = tot + el.crediti;
        });
        setTrainingCreditsTriennioPrecOrdTOT(tot);
    };

    const handleTriennioPrecDeoTOT = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => elem.deontologico && elem.raggruppamento.value === 1).forEach(el => {
            tot = tot + el.crediti;
        });
        setTrainingCreditsTriennioPrecDeoTOT(tot);
    };

    const handleCfpAcquisiti = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => elem.raggruppamento.value !== 1).forEach(el => {
            tot = tot + el.crediti;
        });
        setCfpAcquisiti(tot);
    };


    const handleCfpAcquisitiDeo = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => elem.deontologico && elem.raggruppamento.value !== 1).forEach(el => {
            tot = tot + el.crediti;
        });
        setCfpAcquisitiDeo(tot);
    };


    const handleCfpAcquisitiOrd = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => !elem.deontologico && elem.raggruppamento.value !== 1).forEach(el => {
            tot = tot + el.crediti;
        });
        setCfpAcquisitiOrd(tot);
    };

    const handleCfpDebito = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => elem.raggruppamento.value === 1 && elem.crediti < 0).forEach(el => {
            tot = tot + ((-1) * el.crediti);
        });
        setCfpDebito(tot);
    };

    const handleCfpDebitoDeo = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => elem.deontologico && elem.raggruppamento.value === 1 && elem.crediti < 0).forEach(el => {
            tot = tot + ((-1) * el.crediti);
        });
        setCfpDebitoDeo(tot);
    };

    const handleCfpDebitoOrd = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => !elem.deontologico && elem.raggruppamento.value === 1 && elem.crediti < 0).forEach(el => {
            tot = tot + ((-1) * el.crediti);
        });
        setCfpDebitoOrd(tot);
    };

    const handleCfpCredito = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => elem.raggruppamento.value === 1 && elem.crediti > 0).forEach(el => {
            tot = tot + el.crediti;
        });
        setCfpCredito(tot);
    };

    const handleStatusOkDeo = (credits: Array<IProfessionalStatusEntity>) => {
        let tot = 0;
        credits.filter(elem => elem.deontologico).forEach(el => {
            tot = tot + el.crediti;
        });
        setStatusOkDeo(tot >= 12);
    };

    const handleStatusOkOrd = (credits: Array<IProfessionalStatusEntity>) => {
        let totd: number = 0;
        credits.filter(elem => elem.deontologico).forEach(el => {
            totd = totd + el.crediti;
        });
        
        let toto: number = totd > 12 ? totd - 12 : 0;
        credits.filter(elem => !elem.deontologico).forEach(el => {
            toto = toto + el.crediti;
        });

        setStatusOkOrd(toto >= 48);
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(getProfessionalStatus({ filter: { codiceFiscale: props.codiceFiscale, triennio: filterByThreeYears ? filterByThreeYears.id : 0 } } as IProfessionalStatusThunkPayload))
        .unwrap()
        .then(() => {
            setErr(null);
        })
        .catch((e) => {
            const py : IRejectResponse = e as unknown as IRejectResponse;
            setErr(py);
        })
        .finally(() => setIsLoading(false));
    }, [dispatch, props.codiceFiscale, filterByThreeYears]);

    useEffect(() => {
        handleDeoTOT(trainingCredits);
        handleOrdTOT(trainingCredits);
        handleTriennioPrecDeoTOT(trainingCredits);
        handleTriennioPrecOrdTOT(trainingCredits);
        handleDeoEsoTOT(trainingCredits);
        handleOrdEsoTOT(trainingCredits);

        handleCfpAcquisiti(trainingCredits);
        handleCfpDebito(trainingCredits);
        handleCfpDebitoDeo(trainingCredits);
        handleCfpDebitoOrd(trainingCredits);
        handleCfpCredito(trainingCredits);
        handleCfpAcquisitiDeo(trainingCredits);
        handleCfpAcquisitiOrd(trainingCredits);

        handleStatusOkDeo(trainingCredits);
        handleStatusOkOrd(trainingCredits);
    }, [trainingCredits]);

    const columns: TableColumn<IProfessionalStatusEntity>[] = [
        {
            name: 'Triennio selezionato',
            selector: (row: IProfessionalStatusEntity) => getTriennio(row.triennio, trienni),
            sortable: true,
            omit: filterByThreeYears != null,
            grow: 1,
        },
        {
            name: 'Periodo riferimento',
            selector: (row: IProfessionalStatusEntity) => (
                row.raggruppamento.value === 1 ? 
                    getTriennio(row.triennio - 1, trienni) :
                    (row.raggruppamento.value === 2 ? 
                        (row.crediti < 0 ? getTriennio(row.triennio, trienni) : row.anno) :
                        (row.raggruppamento.value === 3 ? 
                            (row.crediti < 0 ? getTriennio(row.triennio, trienni) : getTriennio(row.triennio + 1, trienni)) : row.anno))),

            sortable: true,
            grow: 1,
        },
        {
            name: 'Attribuzione da',
            selector: (row: IProfessionalStatusEntity) => (
                row.raggruppamento.value === 1 ? 
                    (row.crediti < 0 ? "Debito" : "Credito") + " CFP" :
                    (row.raggruppamento.value === 2 ? 
                        (row.crediti < 0 ? "Eventi (quota decurtata per superato limite definito da linee guida)" : "Eventi") : 
                        (row.raggruppamento.value === 3 ? 
                            (row.crediti < 0 ? "Ravvedimento operoso (riportato nel triennio precedente)" : "Ravvedimento operoso") : "Esoneri"))),
            sortable: true,
            grow: 2,
        },
        {
            name: 'Tipologia formazione',
            selector: (row: IProfessionalStatusEntity) => getTipoFormazione(row.deontologico ? true : false),
            sortable: true,
            grow: 2,
        },
        {
            name: 'CFP',
            selector: (row: IProfessionalStatusEntity) => row.crediti,
            sortable: true,
            grow: 1,
        },
    ] as TableColumn<IProfessionalStatusEntity>[];
    
    return (
        <Container as="section" fluid className="bg-light">
            <Row>
                <Col className="my-3">
                    <h3>RIEPILOGO STATUS FORMATIVO {filterByThreeYears?.nome}</h3>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    {
                        err == null ? (
                            filterByThreeYears == null ? "" : (filterByTrainingType != null ? "" : (
                                <div className="d-flex justify-content-start">
                                    <Card className="m-1 p-2 text-center">
                                        <h4>Credito CFP</h4>
                                        <p className="small"><small>triennio precedente</small></p>
                                        <h3>{cfpCredito}</h3>
                                        <hr className="m-1 p-0" />
                                        <h6>{cfpCredito} <small><small>ord</small></small></h6>
                                    </Card>
                                    <Card className="m-1 p-2 text-center">
                                        <h4>Debito CFP</h4>
                                        <p className="small"><small>triennio precedente</small></p>
                                        <h3>{cfpDebito}</h3>
                                        <hr className="m-1 p-0" />
                                        <h6>{cfpDebitoDeo} <small><small>deo</small></small> + {cfpDebitoOrd} <small><small>ord</small></small></h6>
                                    </Card>
                                    <Card className="m-1 p-2 text-center">
                                        <h4>CFP acquisiti</h4>
                                        <p className="small"><small>triennio selezionato</small></p>
                                        <h3>{cfpAcquisiti}</h3>
                                        <hr className="m-1 p-0" />
                                        <h6>{cfpAcquisitiDeo} <small><small>deo</small></small> + {cfpAcquisitiOrd} <small><small>ord</small></small></h6>
                                    </Card>
                                    <Card className="m-1 p-2 text-center">
                                        <h4>Status CFP</h4>
                                        <p className="small"><small>triennio selezionato</small></p>
                                        <h3>{statusOkDeo && statusOkOrd ? <CircleFill className="text-success" size={64} /> : <CircleFill className="text-danger" size={64} /> }</h3>
                                    </Card>
                                </div>
                            ))
                        ) : ""
                    }
                </Col>
                <Col>
                    <InputGroup className="d-flex justify-content-end">
                        <Button variant="primary" onClick={handleShowModal}><FaRegFilePdf className="text-light" title="pdf" size={20} /></Button>
                        <Triennio defaultSelectedId={filterByThreeYears ? filterByThreeYears.id : 0} onSelect={handleThreeYearsClick} />
                        <TipoFormazione onSelect={handleTrainingTypeClick} />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3">
                    {
                        (err == null) ? (
                            (filterByThreeYears == null) ? "" : (
                                
                                <TrainingCreditsBarSummary 
                                    cfpDeo={trainingCreditsDeoTOT}     
                                    cfpOrd={trainingCreditsOrdTOT} 
                                    cfpTriennioPrecDeo={trainingCreditsTriennioPrecDeoTOT} 
                                    cfpTriennioPrecOrd={trainingCreditsTriennioPrecOrdTOT} 
                                    cfpAcquisitiEsoneriDeo={trainingCreditsDeoEsoTOT} 
                                    cfpAcquisitiEsoneriOrd={trainingCreditsOrdEsoTOT} />    
                            )
                        ) : (
                            <Alert variant="secondary" className="py-3">
                                <h4>{err.error}</h4>
                            </Alert>
                        )
                    }
                </Col>
            </Row>
            <Row>
                <Col className="my-3 h3">DETTAGLIO CFP {filterByThreeYears?.nome}</Col>
            </Row>
            <Row>
                <Col>
                    <DataTable
                        columns={columns}
                        data={trainingCredits}
                        responsive
                        striped
                        dense
                        noDataComponent="Nessun risultato"
                        progressPending={isLoading} />
                </Col>
            </Row>
            <Row>
                <Col className="mt-5 small">
                    Si informa quanto segue:
                    <ul>
                        <li>Il termine <span className="text-decoration-underline">EVENTI</span> <span className="text-decoration-underline">include</span> in sè <span className="text-decoration-underline">CORSI</span> pubblicati e <span className="text-decoration-underline">AUTOCERTIFICAZIONI</span> approvate.</li>
                        {/*}
                        <li>Tutti gli <span className="text-decoration-underline">EVENTI</span> (escludendo i crediti relativi al Triennio Precedente) che vengono epressi con il <span className="text-decoration-underline">segno negativo</span> rappresentano la quota che verrà decurtata in quanto è stato superato il massimo consentito dalle linee guida per il triennio selezionato.</li>
                        <li>Si ricorda che nel caso il triennio selezionato elenchi <span className="text-decoration-underline">EVENTI di competenza del triennio successivo</span> significa che il sistema ha ricalcolato lo status comprendendo il <span className="text-decoration-underline">periodo di RAVVEDIMENTO OPEROSO</span>.</li>
                        {*/}
                    </ul>
                </Col>
            </Row>
            <Modal show={showModal} onHide={handleHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h3>Curriculum formativo</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProfessionalsPdfCv codiceFiscale={props.codiceFiscale} />
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default ProfessionalTrainingCredits;