import React, { FC, Fragment, useState } from 'react';
import { Alert, Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IMetadataOption } from '../../entities/metadata.entity';
import { AppDispatch, RootState } from '../../redux/store';
import { b64toBlob } from '../../utils/attachment-parser';
import Triennio from './dropdown/course/Triennio';
import { IPostExportProfessionalCvFulfilledPayload, postExportProfessionalCv } from '../../redux/slices/documents.slice';
import { IRejectResponse } from '../../api/api.interface';

interface IProps {
    codiceFiscale: string;
}

const ProfessionalsPdfCv: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const trienni: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.trienni);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterByTriennio, setFilterByTriennio] = useState<IMetadataOption | null>(null);
    const [erro, setErro] = useState<IRejectResponse | null>(null);
    
    const handleTriennioClick = (option: IMetadataOption | null) => {
        setFilterByTriennio(option);
    };
    
    const handleExportProfessionalCv = () => {
        if(filterByTriennio) {
            setIsLoading(true);
            setErro(null);
            dispatch(postExportProfessionalCv({ codiceFiscale: props.codiceFiscale, idTriennio: filterByTriennio.id}))
                .unwrap()
                .then((e) => {
                    const payload = e as unknown as IPostExportProfessionalCvFulfilledPayload;
                    const div = document.getElementById("divExportProfessionalCvPDF");
                    if (div) {
                        b64toBlob(payload.attachment.contentRaw, payload.attachment.contentType).then((blobresult: Blob) => {
                            const url = window.URL.createObjectURL(blobresult);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', payload.attachment.filename);
                            div.appendChild(link);
                            link.click();
                            link.remove();
                        });
                    }
                })
                .catch((err) => {
                    const error = err as unknown as IRejectResponse;
                    setErro(error);
                })
                .finally(() => setIsLoading(false));
        }
    };

    return (
        <Fragment>
            <Container fluid as="section">
                <Row>
                    <Col>
                        <Triennio onSelect={handleTriennioClick} excludeIds={[trienni[trienni.length - 1].id]}/>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Button variant="primary" className="w-100" onClick={handleExportProfessionalCv} disabled={isLoading || filterByTriennio == null}>
                            {   
                                isLoading ? (
                                    <div className="text-center">
                                        <Spinner as="span" animation="border" size="sm" role="status" /> <span>Attendere ...</span>
                                    </div>
                                ) : (
                                    <span>Scarica .PDF</span>
                                )
                            }
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="text-center">
                        { erro ? (<Alert variant="danger" className="m-0 p-0">{erro?.error}</Alert>) : (<Fragment />)}
                        <p className="text-secondary small mt-2">Si fa presente che il triennio in corso non è disponibile.</p>
                    </Col>
                </Row>
            </Container>
            <div id="divExportProfessionalCvPDF" className="d-none"></div>
        </Fragment>
    );
};

export default ProfessionalsPdfCv;