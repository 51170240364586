import { format } from 'date-fns';
import React, { FC, Fragment, useState } from 'react';
import { Alert, Badge, Card } from 'react-bootstrap';
import { MdRefresh } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { ICompanyEntity } from '../../entities/company.entity';
import { ICourse } from '../../entities/course.entity';
import { IMetadataOption } from '../../entities/metadata.entity';
import { IUser } from '../../entities/user.entity';
import { UserRole } from '../../enums/user-roles.enum';
import { RootState } from '../../redux/store';
import Subscribe from '../user/Subscribe';
import CourseListItemCFP from './course-list-item-parts/CourseListItemCFP';
import CourseListItemDate from './course-list-item-parts/CourseListItemDate';
import CourseListItemSubscriptions from './course-list-item-parts/CourseListItemSubscriptions';

interface IProps {
  course: ICourse;
  full?: number;
  isGridView?: boolean;
  onClick?: (idCorso: number) => void;
}

const CourseListItem: FC<IProps> = (props) => {

  const companies: ICompanyEntity[] = useSelector((s: RootState) => s.companies.orders);
  const partners: ICompanyEntity[] = useSelector((s: RootState) => s.companies.partners);
  const metaAreaFormativaCorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.area_formativa_corso);
  const metaErogazioneCorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.erogazione_corso);
  const metaTipologiaCorso: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.tipologia_corso);
  const [isReplicateCourse,] = useState<boolean>(props.course.idCorsoRiferimento != null && props.course.idCorsoRiferimento > 0);

  const getAreaFormativa = (id: number) => {
    if (metaAreaFormativaCorso.length) {
      const option = metaAreaFormativaCorso.find((element) => element.id === id);
      if (option) {
        return option.nome;
      }
    }
    return '';
  }

  const getErogazione = (id: number) => {
    if (metaErogazioneCorso.length) {
      const option = metaErogazioneCorso.find((element) => element.id === id);
      if (option) {
        return option.nome;
      }
    }
    return '';
  }

  const getTipologia = (id: number) => {
    if (metaTipologiaCorso.length) {
      const option = metaTipologiaCorso.find((element) => element.id === id);
      if (option) {
        return option.nome;
      }
    }
    return '';
  }

  const getCompany = (id: number) => {
    if (companies.length) {
      const option = companies.find((element) => element.idAzienda === id);
      if (option) {
        return option.nome;
      }
    }
    return '';
  }

  const getPartner = (id: number) => {
    if (partners.length) {
      const option = partners.find((element) => element.idAzienda === id);
      if (option) {
        return option.nome;
      }
    }
    return '';
  }

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (props?.onClick) {
      props.onClick(props.course.id);
    }
  };

  return (
    <Fragment key={props.course.id}>
      {props?.isGridView ? (
        <Card style={{ width: '18rem', minHeight: '18rem' }}>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <CourseListItemCFP cfp={props.course.cpfAssegnati} />
              <span>{props.course?.dataInizio ? format(props.course.dataInizio, 'dd MMM yyyy') : ''}</span>
            </div>
          </Card.Header>
          <Card.Body>
            <section className={`d-flex flex-column ${props?.onClick ? 'c-pointer' : ''}`} onClick={handleClick}>
              <h5 className="text-dark text-wrap text-truncate">{props.course.titolo}</h5>
              <h6 className="text-muted text-wrap text-truncate small">{props.course.codice}</h6>
              {(props.course.nCorso && props.course.nCorso.length > 0) ? (
                <h6 className="text-muted text-wrap text-truncate small">{props.course.nCorso}</h6>
              ) : (
                <Fragment />
              )}
              <div className="d-flex flex-column">
                <p className="small text-wrap text-truncate m-0">{getAreaFormativa(props.course.idAreaFormativa)}</p>
                <p className="small text-wrap text-truncate m-0">{getTipologia(props.course.idTipologia)}</p>
                <p className="small text-wrap text-truncate m-0">{getErogazione(props.course.idErogazione)}</p>
                <div className="d-flex justify-content-end my-1">
                  <CourseListItemSubscriptions
                    min={props.course.nPartecipantiMin}
                    max={props.course.nPartecipantiMax}
                    ext={props.course.nPartecipantiExt}
                    totPresenti={props.course?.countAttendance}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <span className="text-dark text-wrap text-truncate small">{getCompany(props.course.idAziendaProponente)}</span>
                </div>
                <div className="d-flex justify-content-end">
                  {
                    props.course.idAziendaProponente != props.course.idAzienda ? (
                      <span className="text-dark text-wrap text-truncate small"><small><u>{getPartner(props.course.idAzienda)}</u></small></span>
                    ) : (
                      <Fragment />
                    )
                  }
                </div>
                <div className="d-flex justify-content-end my-1">
                  {isReplicateCourse ? <Badge pill bg="info" className="mx-1 text-dark">Replica</Badge> : ""}
                  {
                    props.course.idStatoPubblicazione === 1 ? (
                      <Badge pill bg="dark">bozza</Badge>
                    ) : (
                      props.course.idStatoPubblicazione === 2 ? (
                        <Badge pill bg="warning">da pubblicare</Badge>
                      ) : (
                        props.course.idStatoPubblicazione === 3 ? (
                          <Badge pill bg="success">pubblicato</Badge>
                        ) : (
                          <Fragment />
                        )
                      )
                    )
                  }
                </div>
              </div>
            </section>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex justify-content-center">
              <Subscribe course={props.course} noValidation={false} noPendingList={false} />
            </div>
          </Card.Footer>
        </Card>
      ) : (
        <Card>
          <Card.Body>
              <section className={`d-flex ${props?.onClick ? 'c-pointer' : ''}`} onClick={handleClick}>
                <div className="flex-shrink-1 d-flex flex-column px-2">
                  <CourseListItemDate date={props.course.dataInizio} />
                </div>
                <div className="flex-grow-1 d-flex flex-column align-items-start">
                  <h5 className="text-dark text-wrap text-truncate">{props.course.titolo}</h5>
                  <h6 className="text-muted text-wrap text-truncate small">{props.course.codice}</h6>
                  {(props.course.nCorso && props.course.nCorso.length > 0) ? (
                    <h6 className="text-muted text-wrap text-truncate small">{props.course.nCorso}</h6>
                  ) : (
                    <Fragment />
                  )}
                  <div className="d-flex flex-row">
                    <span className="mb-1 me-1 text-wrap text-truncate border small rounded-pill px-1 border-primary text-primary">{getErogazione(props.course.idErogazione)}</span>
                    <span className="mb-1 me-1 text-wrap text-truncate border small rounded-pill px-1 border-primary text-primary">{getAreaFormativa(props.course.idAreaFormativa)}</span>
                  </div>
                  <span className="text-wrap text-truncate border small rounded-pill px-1 border-primary text-primary">{getTipologia(props.course.idTipologia)}</span>
                </div>
                <div className="flex-shrink-1 d-flex flex-column justify-content-between px-2">
                  <div className="d-flex justify-content-end">
                    <CourseListItemCFP cfp={props.course.cpfAssegnati} />
                  </div>
                  <div className="d-flex justify-content-end">
                    <CourseListItemSubscriptions
                      min={props.course.nPartecipantiMin}
                      max={props.course.nPartecipantiMax}
                      ext={props.course.nPartecipantiExt}
                      totPresenti={props.course.countAttendance}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span className="text-dark text-wrap text-truncate small">{getCompany(props.course.idAziendaProponente)}</span>
                  </div>
                  <div className="d-flex justify-content-end">
                    {
                      props.course.idAziendaProponente != props.course.idAzienda ? (
                        <span className="text-dark text-wrap text-truncate small"><small><u>{getPartner(props.course.idAzienda)}</u></small></span>
                      ) : (
                        <Fragment />
                      )
                    }
                  </div>
                  <div className="d-flex justify-content-end my-1">
                    {isReplicateCourse ? <Badge pill bg="info" className="mx-1 text-dark">Replica</Badge> : ""}
                    {
                      props.course.idStatoPubblicazione === 1 ? (
                        <Badge pill bg="dark">bozza</Badge>
                      ) : (
                        props.course.idStatoPubblicazione === 2 ? (
                          <Badge pill bg="warning">da pubblicare</Badge>
                        ) : (
                          props.course.idStatoPubblicazione === 3 ? (
                            <Badge pill bg="success">pubblicato</Badge>
                          ) : (
                            <Fragment />
                          )
                        )
                      )
                    }
                  </div>
                </div>
              </section>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
};

export default CourseListItem;