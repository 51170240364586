import { ICertificationsProfessionalThunkPayload, ICoursesProfessionalThunkPayload, IGetImateriaHistoryProfessionalStatusThunkPayload, IGetProfessionalThunkPayload, IProfessionalCheckBeforeReplaceCfThunkPayload, IProfessionalReplaceCfThunkPayload, IProfessionalStatusThunkPayload } from '../redux/slices/professional.slice';
import { IPostProfessionalCertificateImportThunkPayload, IPostProfessionalsFromAwnThunkPayload, IProfessionalSearchThunkPayload, IProfessionalsExportStatusCfpThunkPayload, IProfessionalsExportThunkPayload, IProfessionalsThunkPayload } from '../redux/slices/professionals.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getProfessionals = (payload: IProfessionalsThunkPayload) => {
  const endpoint = ApiEndpoints.Professionals.toString();
  let querystring = Array<string>();
  if (payload.filter.text && payload.filter.text.length > 0) {
    querystring.push('text=' + payload.filter.text.toString());
  }
  if (payload.filter.esterno > (-1)) {
    querystring.push('esterno=' + payload.filter.esterno.toString());
  }
  if (payload.filter.statoIscrizione && payload.filter.statoIscrizione.length > 0) {
    querystring.push('statoIscrizione=' + payload.filter.statoIscrizione.toString());
  }
  if (payload.pagination.page && payload.pagination.page > 0) {
    querystring.push('page=' + payload.pagination.page.toString());
  }
  if (payload.pagination.limit && payload.pagination.limit > 0) {
    querystring.push('limit=' + payload.pagination.limit.toString());
  }
  if (payload.sorting.orderby && payload.sorting.orderby.length > 0) {
    querystring.push('orderby=' + payload.sorting.orderby.join(','));
    if (payload.sorting.ordertype) {
      querystring.push('ordertype=desc');
    }
    else {
      querystring.push('ordertype=asc');
    }
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const getProfessionalSearch = (payload: IProfessionalSearchThunkPayload) => {
  const endpoint = ApiEndpoints.ProfessionalSearch.toString();
  let querystring = Array<string>();
  if (payload.text.length > 0) {
    querystring.push('text=' + payload.text.toString());
  }
  if (payload.activetoday > 0) {
    if (payload.activetoday === 1) {
      querystring.push('activetoday=1');
    }
    if (payload.activetoday === 2) {
      querystring.push('activetoday=0');
    }
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const postProfessionalCertificateImport = (payload: IPostProfessionalCertificateImportThunkPayload) => {
  return AxiosService.instance.axios.post(ApiEndpoints.ProfessionalCertificateImport, payload);
};

export const postProfessionalsFromAwn = (payload: IPostProfessionalsFromAwnThunkPayload) => {
  return AxiosService.instance.axios.post(ApiEndpoints.ProfessionalsFromAwn, payload);
};

export const getProfessionalStatus = (payload: IProfessionalStatusThunkPayload) => {
  const endpoint = ApiEndpoints.ProfessionalStatus.toString();
  let querystring = Array<string>();
  if (payload.filter.codiceFiscale && payload.filter.codiceFiscale.length > 0) {
    querystring.push('codiceFiscale=' + payload.filter.codiceFiscale.toString());
  }
  if (payload.filter.triennio && payload.filter.triennio > 0) {
    querystring.push('triennio=' + payload.filter.triennio.toString());
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const getProfessional = (payload: IGetProfessionalThunkPayload) => {
  const url = ApiEndpoints.Professional.replace(':cf', payload.codiceFiscale.toString());
  return AxiosService.instance.axios.get(url);
};

export const getCertificationsProfessional = (payload: ICertificationsProfessionalThunkPayload) => {
  const url = ApiEndpoints.CertificationsProfessional.replace(':cf', payload.codiceFiscale.toString());
  return AxiosService.instance.axios.get(url);
};

export const getCoursesProfessional = (payload: ICoursesProfessionalThunkPayload) => {
  const endpoint = ApiEndpoints.CoursesProfessional.replace(':cf', payload.codiceFiscale.toString());
  let querystring = Array<string>();
  if (payload.filter.confermato > (-1)) {
    querystring.push('confermato=' + payload.filter.confermato.toString());
  }
  if (payload.filter.anno && payload.filter.anno > 0) {
    querystring.push('anno=' + payload.filter.anno.toString());
  }
  if (payload.filter.deontologico > (-1)) {
    querystring.push('deontologico=' + payload.filter.deontologico.toString());
  }
  if (payload.filter.statoIscrizione!=null) {
    querystring.push('statoIscrizione=' + payload.filter.statoIscrizione.toString());
  }
  if (payload.filter.triennio!=null) {
    querystring.push('triennio=' + payload.filter.triennio.toString());
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const getImateriaHistoryProfessionalStatus = (payload: IGetImateriaHistoryProfessionalStatusThunkPayload) => {
  const endpoint = ApiEndpoints.ImateriaHistoryProfessionalStatus.replace(':cf', payload.codiceFiscale.toString());
  let querystring = Array<string>();
  if (payload.filter.triennio && payload.filter.triennio > 0) {
    querystring.push('triennio=' + payload.filter.triennio.toString());
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const getProfessionalsExport = (_payload: IProfessionalsExportThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.ProfessionalsExport);
};

export const getProfessionalsExportStatusCfp = (payload: IProfessionalsExportStatusCfpThunkPayload) => {
  const endpoint = ApiEndpoints.ProfessionalExportStatusCfp.toString();
  let querystring = Array<string>();
  if (payload.idTriennio != null && payload.idTriennio > 0) {
    querystring.push('idTriennio=' + payload.idTriennio.toString());
  }
  if (payload.idStatoIscrizione != null && payload.idStatoIscrizione.length > 0) {
    querystring.push('idStatoIscrizione=' + payload.idStatoIscrizione.toString());
  }
  if (payload.isStatusCfpOk !== null) {
    if (payload.isStatusCfpOk === true) {
      querystring.push('isStatusCfpOk=1');
    }
    else {
      querystring.push('isStatusCfpOk=0');
    }
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.get(url);
};

export const postProfessionalCheckBeforeReplaceCf = (payload: IProfessionalCheckBeforeReplaceCfThunkPayload) => {
  return AxiosService.instance.axios.post(ApiEndpoints.ProfessionalCheckBeforeReplaceCf, payload);
};

export const postProfessionalReplaceCf = (payload: IProfessionalReplaceCfThunkPayload) => {
  return AxiosService.instance.axios.post(ApiEndpoints.ProfessionalReplaceCf, payload);
};

const ProfessionalAPI = {
  getProfessionals,
  postProfessionalCertificateImport,
  getProfessionalStatus,
  getProfessional,
  getCertificationsProfessional,
  getCoursesProfessional,
  getImateriaHistoryProfessionalStatus,
  postProfessionalsFromAwn,
  getProfessionalsExport,
  getProfessionalsExportStatusCfp,
  getProfessionalSearch,
  postProfessionalCheckBeforeReplaceCf,
  postProfessionalReplaceCf,
};

export default ProfessionalAPI;