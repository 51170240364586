import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse, IServerResponse } from '../../api/api.interface';
import CertificationAPI from "../../api/certification.api";
import { IAttachment } from "../../entities/attachment.entity";
import { ICertificationEntity } from "../../entities/certification.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface IInitCertificationThunkPayload {
  certification: ICertificationEntity;
}

export interface IInitCertificationFulfilledPayload {
  certification: ICertificationEntity;
}

export const initCertification = createAsyncThunk(
  'certification/init',
  async (thunkPayload: IInitCertificationThunkPayload, thunkAPI) => {
    const successPayload = thunkPayload.certification as ICertificationEntity;
    const fulfillValue = {
      certification: successPayload
    } as IInitCertificationFulfilledPayload;
    return thunkAPI.fulfillWithValue(fulfillValue);
  }
);

export interface IGetCertificationThunkPayload {
  id: number;
}

export interface IGetCertificationFulfilledPayload {
  certification: ICertificationEntity;
}

export const getCertification = createAsyncThunk(
  'certification/get',
  async (thunkPayload: IGetCertificationThunkPayload, thunkAPI) => {
    try {
      const response = await CertificationAPI.getCertification(thunkPayload);
      const successPayload = response.data as unknown as ICertificationEntity;
      const fulfillValue = {
        certification: successPayload
      } as IGetCertificationFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostCertificationThunkPayload {
  codiceFiscale?: string;
  idOggetto: number;
  idTipologia: number;
  dataRiferimentoDa: string;
  dataRiferimentoA: string;
  titolo: string;
  organizzatore: string;
  codiceCorso: string;
  cfpDichiarati: number;
  cfpAssegnati: number;
  noteRichiedente: string;
  idStatoCertificazione: number;
  allegati: Array<IAttachment>;
}

export interface IPostCertificationFulfilledPayload {
  certification: ICertificationEntity;
}

export const postCertification = createAsyncThunk(
  'certification/post',
  async (thunkPayload: IPostCertificationThunkPayload, thunkAPI) => {
    try {
      const response = await CertificationAPI.postCertification(thunkPayload);
      const successPayload = response.data as unknown as IServerResponse<ICertificationEntity>;
      const fulfillValue = {
        certification: successPayload.data
      } as IPostCertificationFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPutCertificationThunkPayload {
  certification: ICertificationEntity;
}

export interface IPutCertificationFulfilledPayload { }

export const putCertification = createAsyncThunk(
  'certification/put',
  async (thunkPayload: IPutCertificationThunkPayload, thunkAPI) => {
    try {
      const response = await CertificationAPI.putCertification(thunkPayload);
      //const successPayload = response.data;
      const fulfillValue = {} as IPutCertificationFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IDeleteCertificationThunkPayload {
  id: number;
}

export interface IDeleteCertificationFulfilledPayload { }

export const deleteCertification = createAsyncThunk(
  'certification/delete',
  async (thunkPayload: IDeleteCertificationThunkPayload, thunkAPI) => {
    try {
      const response = await CertificationAPI.deleteCertification(thunkPayload);
      //const successPayload = response.data;
      const fulfillValue = {} as IDeleteCertificationFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPostCertificationAttachmentThunkPayload {
  idCertification: number;
  attachment: IAttachment[]; 
}

export interface IPostCertificationAttachmentFulfilledPayload { }

export const postCertificationAttachment = createAsyncThunk(
  'certification/attachment/post',
  async (thunkPayload: IPostCertificationAttachmentThunkPayload, thunkAPI) => {
    try {
      const response = await CertificationAPI.postCertificationAttachment(thunkPayload);
      //const successPayload = response.data as unknown as IAttachmentEntity;
      const fulfillValue = { } as IPostCertificationAttachmentFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICertificationState {
  value: ICertificationEntity | null;
  error: IRejectResponse | null;
}

const initialState: ICertificationState = {
  value: null,
  error: null,
};

const certificationSlice = createSlice({
  name: "certification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initCertification.pending, (state) => {
      state.value = null;
      state.error = null;
    });
    builder.addCase(initCertification.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IInitCertificationFulfilledPayload;
      state.value = payload.certification;
    });
    builder.addCase(initCertification.rejected, (state, action) => { });

    builder.addCase(getCertification.pending, (state) => {
      state.value = null;
      state.error = null;
    });
    builder.addCase(getCertification.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetCertificationFulfilledPayload;
      state.value = payload.certification;
    });
    builder.addCase(getCertification.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
    });

    builder.addCase(postCertification.pending, (state) => {
      state.error = null;
    });
    builder.addCase(postCertification.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IPostCertificationFulfilledPayload;
      state.value = payload.certification;
    });
    builder.addCase(postCertification.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
    });

    builder.addCase(putCertification.pending, (state) => { });
    builder.addCase(putCertification.fulfilled, (state, action) => { });
    builder.addCase(putCertification.rejected, (state, action) => { });

    builder.addCase(deleteCertification.pending, (state) => { });
    builder.addCase(deleteCertification.fulfilled, (state, action) => { });
    builder.addCase(deleteCertification.rejected, (state, action) => { });

    builder.addCase(postCertificationAttachment.pending, (state) => { });
    builder.addCase(postCertificationAttachment.fulfilled, (state, action) => { });
    builder.addCase(postCertificationAttachment.rejected, (state, action) => { });
  },
});

export default certificationSlice.reducer;