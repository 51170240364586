import { FC, Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import ProfessionalTrainingCredits from '../../components/shared/ProfessionalTrainingCredits';
import { IUser } from '../../entities/user.entity';
import { getCompaniesOrder } from '../../redux/slices/companies.slice';
import { getCompany } from '../../redux/slices/company.slice';
import { getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { FaInfoCircle } from 'react-icons/fa';

const UserTrainingCreditsStatus: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompaniesOrder({}));
    //dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
    //dispatch(getMetadataCertification({}));
  }, [dispatch]);

  const user: IUser | null = useSelector((s: RootState) => s.auth.user?.value ? s.auth.user.value : null);

  return (
    <Fragment>
      <PageTitle content="Crediti Formativi">
        <a href="/assets/tutorial/U_CreditiStatus.pdf" target="_blank" rel="noreferrer">
          <FaInfoCircle className="text-dark" size={32} />
        </a>
      </PageTitle>
      <PageSubtitle content="Status" />
      <PageParagraph content="" />
      <Container as="section" fluid>
        <Row>
          <Col>
            {
              user ? (
                <ProfessionalTrainingCredits codiceFiscale={user.fiscalCode} />
              ) : (
                <Fragment />
              )
            }
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserTrainingCreditsStatus;