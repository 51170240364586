import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IRejectResponse } from '../../api/api.interface';
import CertificationAPI from '../../api/certification.api';
import { IAttachment } from "../../entities/attachment.entity";
import { ICertificationEntity } from "../../entities/certification.entity";
import { parseErrorToRejectResponse } from '../../utils/api-reject-parser';

export interface ICertificationsFilter {
  company: number;
  oggetto: number;
  tipologia: number;
  stato: number;
  text: string;
  triennio?: number;
  annoInizio?: number;
  meseInizio?: number;
}

export interface ICertificationsSorting {
  orderby: Array<string>;
  ordertype: boolean;
}

export interface ICertificationsPagination {
  page: number;
  limit: number;
}

export interface ICertificationsThunkPayload {
  filter: ICertificationsFilter;
  sorting: ICertificationsSorting;
  pagination: ICertificationsPagination;
}

export interface ICertificationsFulfilledPayload {
  certifications: Array<ICertificationEntity>;
  countTotalCertifications: number;
}

export const getCertifications = createAsyncThunk(
  'certifications/get',
  async (thunkPayload: ICertificationsThunkPayload, thunkAPI) => {
    try {
      const response = await CertificationAPI.getCertifications(thunkPayload);
      const successPayload = response.data as unknown as Array<ICertificationEntity>;
      const recordHeaders = response.headers["x-count-records"];
      const countTotalCertifications = Number(recordHeaders ? recordHeaders : 0);
      const fulfillValue = {
        certifications: successPayload,
        countTotalCertifications: countTotalCertifications
      } as ICertificationsFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IPutCertificationsStatusThunkPayload {
  status: number;
  ids: Array<number>;
}

export interface IPutCertificationsStatusFulfilledPayload { }

export const putCertificationsStatus = createAsyncThunk(
  'certifications/status/put',
  async (thunkPayload: IPutCertificationsStatusThunkPayload, thunkAPI) => {
    try {
      const response = await CertificationAPI.putCertificationsStatus(thunkPayload);
      const fulfillValue = {} as IPutCertificationsStatusFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICertificationsExportThunkPayload { }

export interface ICertificationsExportFulfilledPayload {
  attachment: IAttachment;
}

export const getCertificationsExport = createAsyncThunk(
  'certifications/export/get',
  async (thunkPayload: ICertificationsExportThunkPayload, thunkAPI) => {
    try {
      const response = await CertificationAPI.getCertificationsExport(thunkPayload);
      const successPayload = response.data as unknown as IAttachment;
      const fulfillValue = {
        attachment: successPayload,
      } as ICertificationsExportFulfilledPayload;
      return thunkAPI.fulfillWithValue(fulfillValue);
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;
      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ICertificationsState {
  error: IRejectResponse | null;
  value: Array<ICertificationEntity>;
  total: number;
}

const initialState: ICertificationsState = {
  error: null,
  value: [],
  total: 0,
};

const certificationsSlice = createSlice({
  name: "certifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCertifications.pending, (state) => {
      state.error = null;
      state.value = [];
    });
    builder.addCase(getCertifications.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ICertificationsFulfilledPayload;
      state.value = payload.certifications;
      state.total = payload.countTotalCertifications;
    });
    builder.addCase(getCertifications.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;
      state.error = payload;
      state.value = [];
    });

    builder.addCase(putCertificationsStatus.pending, (state) => { });
    builder.addCase(putCertificationsStatus.fulfilled, (state, action) => { });
    builder.addCase(putCertificationsStatus.rejected, (state, action) => { });
  },
});

export default certificationsSlice.reducer;