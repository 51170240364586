import { Formik } from 'formik';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Asterisk, CheckCircleFill } from 'react-bootstrap-icons';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { IAttachment } from '../../entities/attachment.entity';
import { IMetadataOptionNode } from '../../entities/metadata.entity';
import { IPostCertificationThunkPayload, postCertification } from '../../redux/slices/certification.slice';
import { getMetadataCertification } from '../../redux/slices/metadata.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { parseISOStringToStandardDate } from '../../utils/course-parser';

interface IFormValue extends IPostCertificationThunkPayload {
    responsabilita: boolean;
};

interface IProps {
    codiceFiscale: string
}

const RequestCertificationForm: FC<IProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [tipoFileErrato, setTipoFileErrato] = useState<string>('');

    const oggettoCertificazione: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.oggetto_certificazione);
    const tipologiaCertificazione: Array<IMetadataOptionNode> = useSelector((s: RootState) => s.metadata.certification.tipologia_certificazione);

    const initialValues: IFormValue = {
        codiceFiscale: props.codiceFiscale,
        idOggetto: 0,
        idTipologia: 0,
        dataRiferimentoDa: parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 0, 1).toISOString()),
        dataRiferimentoA: parseISOStringToStandardDate(new Date((new Date()).getFullYear(), 11, 31).toISOString()),
        titolo: '',
        organizzatore: '',
        codiceCorso: '',
        cfpDichiarati: 0,
        cfpAssegnati: 0,
        noteRichiedente: '',
        idStatoCertificazione: 1,
        responsabilita: false,
        allegati: new Array<IAttachment>(),
    };

    const handleCloseTipoFileErrato = () => {
        setTipoFileErrato('');
    };

    const schema = yup.object().shape({
        idOggetto: yup.number().required("Campo obbligatorio").moreThan(0, "Campo obbligatorio"),
        idTipologia: yup.number().required("Campo obbligatorio"),
        titolo: yup.string().max(500,'Errore di validazione! Valore massimo consentito di caratteri 500'),
        organizzatore: yup.string().max(250,'Errore di validazione! Valore massimo consentito di caratteri 250'),
        codiceCorso: yup.string().max(50,'Errore di validazione! Valore massimo consentito di caratteri 50'),
        cfpDichiarati: yup.number().required("Campo obbligatorio")
            .when("idOggetto", {
                is: 1,
                then: yup.number().when("idTipologia", {
                    is: 0, // Corsi abilitanti anche di aggiornamento
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 1, // Master universitario di I e II livello, assegni di ricerca, dottorato di ricerca, scuole di specializzazione e corsi di perfezionamento universitari, II laurea od ulteriore laurea
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(60, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 2, // Mostre, fiere, visite ed altri eventi assimilabili
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(1, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 3, // Monografie, articoli e saggi scientifici o di natura tecnico-professionale
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(2, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 6, // Partecipazione attiva degli iscritti all'Ordine in qualità di relatori non retribuiti ad eventi formativi accreditati promossi dall'Ordine o da sogetti terzi (art.5.2)
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(8, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 8, // Esercitazioni e mobilitazioni di protezione civile
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 9, // Formazione erogata da Enti Pubblici ai propri dipendenti
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 13, // Biennale di Venezia
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 14, // ESTERO - Corso di aggiornamento e sviluppo professionale
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 15, // ESTERO - Seminari,Convegni,Giornate di Studio, Tavole Rotonde, Conferenze, Workshop et Similia
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(8, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 17, // Corsi o seminari erogati da altri Ordini/Collegi
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 18, // Biennale di Venezia (meeting)
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 20, // Premi e menzioni per la partecipazione a concorsi di progettazione (art.5.4 lettera f)
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }).when("idTipologia", {
                    is: 21, // Corsi o seminari erogati da Enti pubblici di chiara valenza formativa: Regioni, ENEA, CNR
                    then: yup.number().min(1, ({ min }) => `Errore di validazione! Valore minimo consentito ${min}`)
                        .max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`)
                }),
                otherwise: yup.number().max(20, ({ max }) => `Errore di validazione! Valore massimo consentito ${max}`),
            }),
        responsabilita: yup.bool().oneOf([true], "Accetta l'assunzione di responsabilita per proseguire"),
        dataRiferimentoDa: yup.date().required("Selezionare data inizio"),
        dataRiferimentoA: yup.date().required("Selezionare data fine"),
        noteRichiedente: yup.string().max(300,'Errore di validazione! Valore massimo consentito di caratteri 300')
    });

    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const [submittedConfirmed, setSubmittedConfirmed] = useState(false);

    const handleClose = () => {
        setShow(false);
        setSubmittedConfirmed(true);
    };

    const handleShow = () => setShow(true);

    useEffect(() => {
        dispatch(getMetadataCertification({}))
    }, [dispatch]);

    // const [uploadAttachment, setUploadAttachment] = useState<IAttachment>({
    //     filename: '',
    //     contentType: '',
    //     contentRaw: '',
    // });

    const [uploadAttachment, setUploadAttachment] = useState<IAttachment[]>([]);
    
    const readFileContents = async (file: File) => {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                resolve(fileReader.result);
                if (e.target?.result) {
                    const res = e.target.result;

                    setUploadAttachment(oa => [...oa, {
                        filename: file.name,
                        contentType: file.type,
                        contentRaw: res.toString(),
                    }]);
                }
            };
            fileReader.onerror = reject;
            fileReader.readAsDataURL(file);
        });
    };
    const readAllFiles = async (AllFiles: Array<File>) => {
        const results = await Promise.all(
            AllFiles.map(async file => {
                const fileContents = await readFileContents(file);
                return fileContents;
            })
        );
        // console.log(results, "resutls");
        setIsDisable(false);
        return results;
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const files = event.target.files as FileList;
        let AllFiles = [];

        if (files.length > 0 && files.length < 5) {
            //console.log("tot files: " + files.length);
            let tipiValidi = true;
            for (var i = 0; i < files.length; i++) {
                AllFiles.push(files[i]);

                if (!((files[i].type === "application/pdf") || (files[i].type === "application/x-zip-compressed"))) {
                    tipiValidi = false;
                }
            }

            if (tipiValidi) {
                readAllFiles(AllFiles)
                    .then(result => {
                        result.map(r => {
                            console.log(r);
                        });
                    });

                    // setIsDisable(false);
            } else {
                setTipoFileErrato('tipo file errato, consentiti file zip e pdf');
                setUploadAttachment([]);
                event.currentTarget.value = "";
            }
        } else {
            setTipoFileErrato('il numero massimo di file consentito è 4');
            setUploadAttachment([]);
            event.currentTarget.value = "";
        }        

        // if (files.length) {
        //     const file_name = files[0].name;
        //     const file_type = files[0].type;

        //     const fileReader = new FileReader();
        //     fileReader.onload = (e: ProgressEvent<FileReader>) => {
        //         if (e.target?.result) {
        //             const res = e.target.result;
        //             setUploadAttachment({
        //                 filename: file_name,
        //                 contentType: file_type,
        //                 contentRaw: res.toString(),
        //             });
        //         }
        //     };

        //     fileReader.readAsDataURL(files[0]);
        //     setIsDisable(false);
        // }
    };

    const handleSubmit = (value: IFormValue) => {
        //value.allegati.push(uploadAttachment);
        // const val: IFormValue = { ...value, cfpAssegnati: value.cfpDichiarati, allegati: [uploadAttachment] };
        const val: IFormValue = { ...value, cfpAssegnati: value.cfpDichiarati, allegati: uploadAttachment };
        setError(false);
        setLoading(true);
        dispatch(postCertification(val))
            .unwrap()
            .then(() => {
                // console.log("post completata");
                setShow(true);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const [test, setTest] = useState(0);

    return (
        <Fragment>
            <div className={`${submittedConfirmed ? 'd-none' : ''}`}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, handleChange, values, errors, touched }) => (
                        <div className="mx-3">
                            <Form onSubmit={handleSubmit}>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Oggetto<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            as="select"
                                            name="idOggetto"
                                            onChange={handleChange}
                                            disabled={isLoading || submittedConfirmed}
                                            isInvalid={!!touched.idOggetto && !!errors.idOggetto}
                                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => { setTest(Number(e.target.value)); }}
                                        >
                                            <option key={'idOggetto0000'} value={0}>Seleziona oggetto</option>
                                            {oggettoCertificazione.filter((itm: IMetadataOptionNode) => itm.id === 1 || itm.id === 3).map((item: IMetadataOptionNode, index: number) => {
                                                return (<option key={"idOggetto" + item.id + index} value={item.id}>{item.nome}</option>);
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.idOggetto}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Tipologia<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            as="select"
                                            name="idTipologia"
                                            onChange={handleChange}
                                            disabled={isLoading || submittedConfirmed}
                                            isInvalid={!!touched.idTipologia && !!errors.idTipologia}
                                        >
                                            {tipologiaCertificazione.filter(item2 => item2.extId === test).map((item: IMetadataOptionNode, index: number) => {
                                                return (<option key={"idTipologia" + item.id + index} value={item.id}>{item.nome}</option>);
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{errors.idTipologia}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className={`my-3 ${values.idOggetto == 1 ? '' : 'd-none'}`} >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Titolo corso</Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            type="text"
                                            name="titolo"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.titolo}
                                            onChange={handleChange}
                                            isInvalid={!!touched.titolo && !!errors.titolo}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.titolo}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className={`my-3 ${values.idOggetto == 1 ? '' : 'd-none'}`}>
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Organizzatore</Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            type="text"
                                            name="organizzatore"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.organizzatore}
                                            onChange={handleChange}
                                            isInvalid={!!touched.organizzatore && !!errors.organizzatore}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.organizzatore}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className={`my-3 ${values.idOggetto == 1 ? '' : 'd-none'}`}>
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Codice corso</Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            type="text"
                                            name="codiceCorso"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.codiceCorso}
                                            onChange={handleChange}
                                            isInvalid={!!touched.codiceCorso && !!errors.codiceCorso}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.codiceCorso}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3" >
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>CFP dichiarati<Asterisk size={8} className="mb-3" />
                                        <a href="/assets/tutorial/A_TabellaAssegnazioneCfp.pdf" target="_blank" rel="noreferrer">
                                            <FaInfoCircle className="text-dark" title="Vedi la tabella di assegnazione cfp" size={22} />
                                        </a>
                                    </Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            min={0}
                                            type="number"
                                            name="cfpDichiarati"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.cfpDichiarati}
                                            onChange={handleChange}
                                            isInvalid={!!touched.cfpDichiarati && !!errors.cfpDichiarati}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.cfpDichiarati}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Periodo rif.<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={3} md={4} sm={12}>
                                        <Form.Control
                                            type="date"
                                            name="dataRiferimentoDa"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.dataRiferimentoDa}
                                            onChange={handleChange}
                                            isInvalid={!!touched.dataRiferimentoDa && !!errors.dataRiferimentoDa}
                                        />
                                        <Form.Text>Inizio</Form.Text>
                                        <Form.Control.Feedback type="invalid">{errors.dataRiferimentoDa}</Form.Control.Feedback>
                                    </Col>
                                    <Col lg={3} md={4} sm={12}>
                                        <Form.Control
                                            type="date"
                                            name="dataRiferimentoA"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.dataRiferimentoA}
                                            onChange={handleChange}
                                            isInvalid={!!touched.dataRiferimentoA && !!errors.dataRiferimentoA}
                                        />
                                        <Form.Text>Fine</Form.Text>
                                        <Form.Control.Feedback type="invalid">{errors.dataRiferimentoA}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Carica allegato<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            type="file" multiple
                                            name="allegato"
                                            disabled={isLoading || submittedConfirmed}
                                            onChange={handleFileUpload}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Note</Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Control
                                            as="textarea"
                                            name="noteRichiedente"
                                            disabled={isLoading || submittedConfirmed}
                                            value={values.noteRichiedente}
                                            onChange={handleChange}
                                            isInvalid={!!touched.noteRichiedente && !!errors.noteRichiedente}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.noteRichiedente}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="my-3">
                                    <Form.Label as={Col} lg={2} md={4} sm={12}>Assunzione di responsabilità<Asterisk size={8} className="mb-3" /></Form.Label>
                                    <Col lg={6} md={8} sm={12}>
                                        <Form.Check type="switch">
                                            <Form.Check.Input
                                                onChange={handleChange}
                                                name="responsabilita"
                                                defaultChecked={values.responsabilita}
                                                disabled={isLoading || submittedConfirmed}
                                                isInvalid={!!touched.responsabilita && !!errors.responsabilita}
                                            />
                                            <Form.Check.Label>
                                                <i>Tutte le informazioni sono soggette alla normativa vigente relativa alle <b>sanzoni penali</b>, nel caso di dichiarazioni mendaci, di formazione o uso di atti falsi, richiamate dall'art. 76 del D.P.R. 28/12/2000 n.445 in materia di Documentazione Amministrativa.</i>
                                            </Form.Check.Label>
                                            <Form.Control.Feedback type="invalid">{errors.responsabilita}</Form.Control.Feedback>
                                        </Form.Check>
                                    </Col>
                                </Form.Group>

                                {
                                    error ? (
                                        <Alert variant='danger' className="my-3">
                                            Si è verificato un errore durante il salvataggio della richiesta.
                                        </Alert>
                                    ) : (
                                        <Fragment />
                                    )
                                }

                                <Button type="submit" className="w-10" size="lg" variant="primary" disabled={isLoading || isDisable || submittedConfirmed}>
                                    {isLoading ? (
                                        <Fragment>
                                            <Spinner as="span" animation="border" size="sm" role="status" />
                                            <span className="ms-1">Attendi</span>
                                            <span>...</span>
                                        </Fragment>
                                    ) : (
                                        <span>Invia</span>
                                    )}
                                </Button>
                                <p className="my-1">Caricare almeno un allegato per abilitare il bottone</p>
                            </Form>
                        </div>
                    )
                    }
                </Formik>
            </div>

            <Modal show={show} centered>
                <Modal.Header >
                    <Modal.Title>Operazione completata con successo!</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <CheckCircleFill size={100} className="text-success" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose} >
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={tipoFileErrato.length > 0} centered>
                <Modal.Header >
                    <Modal.Title>Errore! </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p>{tipoFileErrato}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseTipoFileErrato} >
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default RequestCertificationForm;
