import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { IUser } from './entities/user.entity';
import {
  Routes as r,
  UnauthenticatedRoutes as ur,
  AuthenticatedRoutes as ar,
  AuthenticatedRoutesMaster as armaster,
  AuthenticatedRoutesSupervisor as arsupervisor,
  AuthenticatedRoutesManager as armanager,
  AuthenticatedRoutesAdmin as aradmin, 
  AuthenticatedRoutesPartner as arpartner,
  AuthenticatedRoutesUser as aruser
} from './enums/routes.enum';
import AdminCertifications from './pages/AdminPages/AdminCertifications';
import AdminCourse from './pages/AdminPages/AdminCourse';
import AdminCourseDuplica from './pages/AdminPages/AdminCourseDuplica';
import AdminCourseReplica from './pages/AdminPages/AdminCourseReplica';
import AdminDashboard from './pages/AdminPages/AdminDashboard';
import AdminImport from './pages/AdminPages/AdminImport';
import AdminImportProfessional from './pages/AdminPages/AdminImportProfessional';
import AdminNewCertification from './pages/AdminPages/AdminNewCertification';
import AdminNewCourse from './pages/AdminPages/AdminNewCourse';
import AdminProfessional from './pages/AdminPages/AdminProfessional';
import AdminProfessionals from './pages/AdminPages/AdminProfessionals';
import AdminPublishedCourses from './pages/AdminPages/AdminPublishedCourses';
import AdminTeachers from './pages/AdminPages/AdminTeachers';
import AdminUnpublishedCourses from './pages/AdminPages/AdminUnpublishedCourses';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import Login from './pages/Login';
import LoginSSO from './pages/LoginSSO';
import ManagerAttachments from './pages/ManagerPages/ManagerAttachments';
import ManagerDashboard from './pages/ManagerPages/ManagerDashboard';
import ManagerProfessional from './pages/ManagerPages/ManagerProfessional';
import ManagerProfessionals from './pages/ManagerPages/ManagerProfessionals';
import ManagerReplaceCf from './pages/ManagerPages/ManagerReplaceCf';
import ManagerSupportOtp from './pages/ManagerPages/ManagerSupportOtp';
import ManagerTutorial from './pages/ManagerPages/ManagerTutorial';
import MasterAttachments from './pages/MasterPages/MasterAttachments';
import MasterAwn from './pages/MasterPages/MasterAwn';
import MasterDashboard from './pages/MasterPages/MasterDashboard';
import NotFound from './pages/NotFound';
import NotImplemented from './pages/NotImplemented';
import PartnerCourse from './pages/PartnerPages/PartnerCourse';
import PartnerCourseDuplica from './pages/PartnerPages/PartnerCourseDuplica';
import PartnerCourseImportFromImateria from './pages/PartnerPages/PartnerCourseImportFromImateria';
import PartnerCourseReplica from './pages/PartnerPages/PartnerCourseReplica';
import PartnerDashboard from './pages/PartnerPages/PartnerDashboard';
import PartnerNewCourse from './pages/PartnerPages/PartnerNewCourse';
import PartnerPublishedCourses from './pages/PartnerPages/PartnerPublishedCourses';
import PartnerUnpublishedCourses from './pages/PartnerPages/PartnerUnpublishedCourses';
import Profile from './pages/Profile';
import RedirectSSO from './pages/RedirectSSO';
import ResetPasswordPage from './pages/ResetPasswordPage';
import Support from './pages/Support';
import UserCourse from './pages/UserPages/UserCourse';
import UserCreditNewRequest from './pages/UserPages/UserCreditNewRequest';
import UserCreditRequest from './pages/UserPages/UserCreditRequest';
import UserDashboard from './pages/UserPages/UserDashboard';
import UserFaq from './pages/UserPages/UserFaq';
import UserFavoritesCourses from './pages/UserPages/UserFavoritesCourses';
import UserMyCourses from './pages/UserPages/UserMyCourses';
import UserPublishedCourses from './pages/UserPages/UserPublishedCourses';
import UserScheduledCourses from './pages/UserPages/UserScheduledCourses';
import UserTrainingCreditsStatus from './pages/UserPages/UserTrainingCreditsStatus';
import { RootState } from './redux/store';
import { UnauthenticatedRoute as NoAuth } from './router/UnauthenticatedRoute';
import { AuthenticatedRoute as AuthRoute } from './router/AuthenticatedRoute';
import { AuthenticatedRouteMaster as AuthMaster } from './router/AuthenticatedRouteMaster';
import { AuthenticatedRouteSupervisor as AuthSupervisor } from './router/AuthenticatedRouteSupervisor';
import { AuthenticatedRouteManager as AuthManager } from './router/AuthenticatedRouteManager';
import { AuthenticatedRouteAdmin as AuthAdmin } from './router/AuthenticatedRouteAdmin';
import { AuthenticatedRoutePartner as AuthPartner } from './router/AuthenticatedRoutePartner';
import { AuthenticatedRouteUser as AuthUser } from './router/AuthenticatedRouteUser';
import AdminOtherCourses from './pages/AdminPages/AdminOtherCourses';
import AdminCourseOther from './pages/AdminPages/AdminCourseOther';
import SupervisorDashboard from './pages/SupervisorPages/SupervisorDashboard';
import SupervisorAdvices from './pages/SupervisorPages/SupervisorAdvices';
import SupervisorNewAdvice from './pages/SupervisorPages/SupervisorNewAdvice';
import SupervisorAdvice from './pages/SupervisorPages/SupervisorAdvice';
import UserCourseAttemptPayment from './pages/UserPages/UserCourseAttemptPayment';
import InspectDocument from './pages/InspectDocument';
import InspectDocumentNoCode from './pages/InspectDocumentNoCode';

const RouterOutlet: FC = () => {
  const user: IUser | null = useSelector((s: RootState) => s.auth.user?.value || null);

  return (
    <Routes>
      <Route path={r.NotFound} element={<NoAuth user={user} component={NotFound} />} />
      <Route path={r.RedirectSSO} element={<NoAuth user={user} component={RedirectSSO} />} />
      <Route path={r.LoginSSO} element={<NoAuth user={user} component={LoginSSO} />} />
      <Route path={r.Root} element={<NoAuth user={user} component={RedirectSSO} />} />

      <Route path={ur.Register} element={<NoAuth user={user} component={NotImplemented} />} />
      <Route path={ur.VerifyAccount} element={<NoAuth user={user} component={NotImplemented} />} />
      <Route path={ur.VerifyAccountToken} element={<NoAuth user={user} component={NotImplemented} />} />
      <Route path={ur.ForgotPassword} element={<NoAuth user={user} component={ForgotPasswordPage} />} />
      <Route path={ur.ResetPassword} element={<NoAuth user={user} component={ResetPasswordPage} />} />
      <Route path={ur.ResetPasswordToken} element={<NoAuth user={user} component={NotImplemented} />} />
      <Route path={ur.Login} element={<NoAuth user={user} component={Login} />} />
      <Route path={ur.InspectDocument} element={<NoAuth user={user} component={InspectDocument} />} />
      <Route path={ur.InspectDocumentNoCode} element={<NoAuth user={user} component={InspectDocumentNoCode} />} />

      <Route path={ar.Support} element={<AuthRoute user={user} component={Support} />} />
      <Route path={ar.Me} element={<AuthRoute user={user} component={Profile} />} />

      <Route path={armaster.Dashboard} element={<AuthMaster user={user} component={MasterDashboard} />} />
      <Route path={armaster.PublishedCourses} element={<AuthMaster user={user} component={NotImplemented} />} />
      <Route path={armaster.Attachments} element={<AuthMaster user={user} component={MasterAttachments} />} />
      <Route path={armaster.Settings} element={<AuthMaster user={user} component={NotImplemented} />} />
      <Route path={armaster.Awn} element={<AuthMaster user={user} component={MasterAwn} />} />
      
      <Route path={arsupervisor.Dashboard} element={<AuthSupervisor user={user} component={SupervisorDashboard} />} />
      <Route path={arsupervisor.AdviceList} element={<AuthSupervisor user={user} component={SupervisorAdvices} />} />
      <Route path={arsupervisor.NewAdvice} element={<AuthSupervisor user={user} component={SupervisorNewAdvice} />} />
      <Route path={arsupervisor.Advice} element={<AuthSupervisor user={user} component={SupervisorAdvice} />} />

      <Route path={armanager.Dashboard} element={<AuthManager user={user} component={ManagerDashboard} />} />
      <Route path={armanager.ListProfessionals} element={<AuthManager user={user} component={ManagerProfessionals} />} />
      <Route path={armanager.Professional} element={<AuthManager user={user} component={ManagerProfessional} />} />
      <Route path={armanager.Attachments} element={<AuthManager user={user} component={ManagerAttachments} />} />
      <Route path={armanager.Tutorials} element={<AuthManager user={user} component={ManagerTutorial} />} />
      <Route path={armanager.Otp} element={<AuthManager user={user} component={ManagerSupportOtp} />} />
      <Route path={armanager.ReplaceCf} element={<AuthManager user={user} component={ManagerReplaceCf} />} />

      <Route path={aradmin.Dashboard} element={<AuthAdmin user={user} component={AdminDashboard} />} />
      <Route path={aradmin.Course} element={<AuthAdmin user={user} component={AdminCourse} />} />
      <Route path={aradmin.CourseOther} element={<AuthAdmin user={user} component={AdminCourseOther} />} />
      <Route path={aradmin.PublishedCourses} element={<AuthAdmin user={user} component={AdminPublishedCourses} />} />
      <Route path={aradmin.UnpublishedCourses} element={<AuthAdmin user={user} component={AdminUnpublishedCourses} />} />
      <Route path={aradmin.OtherCourses} element={<AuthAdmin user={user} component={AdminOtherCourses} />} />
      <Route path={aradmin.ImportCourses} element={<AuthAdmin user={user} component={AdminImport} />} />
      <Route path={aradmin.NewCourse} element={<AuthAdmin user={user} component={AdminNewCourse} />} />
      <Route path={aradmin.NewCertification} element={<AuthAdmin user={user} component={AdminNewCertification} />} />
      <Route path={aradmin.Teachers} element={<AuthAdmin user={user} component={AdminTeachers} />} />
      <Route path={aradmin.ListProfessionals} element={<AuthAdmin user={user} component={AdminProfessionals} />} />
      <Route path={aradmin.ImportProfessionals} element={<AuthAdmin user={user} component={AdminImportProfessional} />} />
      <Route path={aradmin.Professional} element={<AuthAdmin user={user} component={AdminProfessional} />} />
      <Route path={aradmin.TrainingCreditsManage} element={<AuthAdmin user={user} component={AdminCertifications} />} />
      <Route path={aradmin.ReplicaCourse} element={<AuthAdmin user={user} component={AdminCourseReplica} />} />
      <Route path={aradmin.DuplicaCourse} element={<AuthAdmin user={user} component={AdminCourseDuplica} />} />

      <Route path={arpartner.Dashboard} element={<AuthPartner user={user} component={PartnerDashboard} />} />
      <Route path={arpartner.PublishedCourses} element={<AuthPartner user={user} component={PartnerPublishedCourses} />} />
      <Route path={arpartner.UnpublishedCourses} element={<AuthPartner user={user} component={PartnerUnpublishedCourses} />} />
      <Route path={arpartner.ImportCourses} element={<AuthPartner user={user} component={PartnerCourseImportFromImateria} />} />
      <Route path={arpartner.Course} element={<AuthPartner user={user} component={PartnerCourse} />} />
      <Route path={arpartner.NewCourse} element={<AuthPartner user={user} component={PartnerNewCourse} />} />
      <Route path={arpartner.ReplicaCourse} element={<AuthPartner user={user} component={PartnerCourseReplica} />} />
      <Route path={arpartner.DuplicaCourse} element={<AuthPartner user={user} component={PartnerCourseDuplica} />} />

      <Route path={aruser.Dashboard} element={<AuthUser user={user} component={UserDashboard} />} />
      <Route path={aruser.PublishedCourses} element={<AuthUser user={user} component={UserPublishedCourses} />} />
      <Route path={aruser.FavoritesCourses} element={<AuthUser user={user} component={UserFavoritesCourses} />} /> 
      <Route path={aruser.ScheduledCourses} element={<AuthUser user={user} component={UserScheduledCourses} />} />    
      <Route path={aruser.MyCourses} element={<AuthUser user={user} component={UserMyCourses} />} />
      <Route path={aruser.TrainingCreditsStatus} element={<AuthUser user={user} component={UserTrainingCreditsStatus} />} />
      <Route path={aruser.TrainingCreditsRequests} element={<AuthUser user={user} component={UserCreditRequest} />} />
      <Route path={aruser.TrainingCreditsNewRequest} element={<AuthUser user={user} component={UserCreditNewRequest} />} />
      <Route path={aruser.Faq} element={<AuthUser user={user} component={UserFaq} />} />
      <Route path={aruser.Course} element={<AuthUser user={user} component={UserCourse} />} />
      <Route path={aruser.CourseAttemptPayment} element={<AuthUser user={user} component={UserCourseAttemptPayment} />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouterOutlet;
