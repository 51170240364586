import { FC, Fragment } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import Tutorials from '../../components/shared/Tutorials';

const ManagerTutorial: FC = () => {

    return (
        <Fragment>
            <PageTitle content="Faq" />
            <PageSubtitle content="Manuali / Guide" />
            <PageParagraph content="" />
            <Tutorials />
        </Fragment>
    );
};

export default ManagerTutorial;