import { FC, Fragment, useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import PrivacyPolicyAgreement from '../../components/shared/PrivacyPolicyAgreement';
import { AuthenticatedRoutesUser } from '../../enums/routes.enum';
import { getCompaniesOrder, getCompaniesPartner } from '../../redux/slices/companies.slice';
import { getCompany } from '../../redux/slices/company.slice';
import { getMetadataCertification, getMetadataCourse } from '../../redux/slices/metadata.slice';
import { AppDispatch } from '../../redux/store';
import Notifications from '../../components/user/Notifications';
import AdvicesReadonly from '../../components/shared/AdvicesReadonly';

const UserDashboard: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleClickGoToCourses = () => {
    let route = null;
    route = AuthenticatedRoutesUser.PublishedCourses.toString();
    navigate(route);
  };

  const handleClickGoToStatus = () => {
    let route = null;
    route = AuthenticatedRoutesUser.TrainingCreditsStatus.toString();
    navigate(route);
  };

  useEffect(() => {
    dispatch(getCompany({}));
    dispatch(getCompaniesOrder({}));
    dispatch(getCompaniesPartner({}));
    dispatch(getMetadataCourse({}));
    dispatch(getMetadataCertification({}));
  }, [dispatch]);

  return (
    <Fragment>
      <PageTitle content="Home" />
      <PageSubtitle content="Benvenuto professionista!" />
      <PageParagraph content="" />
      <AdvicesReadonly />
      <Notifications />
      <Container fluid as="section">
        <Row className="d-flex align-items-stretch my-5">
          <Col xs={12} md={6} className="my-5">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="/assets/list.svg"
                height={128}
                className="position-absolute top-0 start-50 translate-middle c-pointer"
                onClick={handleClickGoToCourses}
              />
              <Card.Body className="pt-5">
                <h4 className="mt-5 text-center text-color-palette-1 c-pointer" onClick={handleClickGoToCourses}>Corsi</h4>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} className="my-5">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="/assets/graduation-cap.svg"
                height={128}
                className="position-absolute top-0 start-50 translate-middle c-pointer"
                onClick={handleClickGoToStatus}
              />
              <Card.Body className="pt-5">
                <h4 className="mt-5 text-center text-color-palette-3 c-pointer" onClick={handleClickGoToStatus}>Status</h4>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <PrivacyPolicyAgreement />
    </Fragment>
  )
};

export default UserDashboard;
