import { FC, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import Tutorials from '../../components/shared/Tutorials';

const UserFaq: FC = () => {
  return (
    <Fragment>
      <PageTitle content="Faq" />
      <PageSubtitle content="Contatta il supporto!" />
      <PageParagraph content="" />
      <Container fluid as="section">
        <Row>
          <Col className="mb-5">
            <p><a href="mailto:teamformazione@cnappc.it"><strong>Clicca QUI per richiedere assistenza tramite posta elettronica.</strong></a></p>
            <p><i>Alla suddetta assistenza vanno indirizzate <b>esclusivamente</b> richieste relative all'<b>uso del portale</b></i>.</p>
          </Col>
        </Row>
      </Container>
      <PageSubtitle content="Manuali / Guide" />
      <Tutorials />
    </Fragment>
  );
};

export default UserFaq;