import { FC, Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IMetadataOption } from '../../../../entities/metadata.entity';
import { RootState } from '../../../../redux/store';
import { BsFillCircleFill } from "react-icons/bs";


export const getTipologia = (id: number, list: Array<IMetadataOption>): string => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option.nome;
    }
  }
  return '';
}

export const getTipologiaOption = (id: number, list: Array<IMetadataOption>): IMetadataOption | null => {
  if (list.length) {
    const option = list.find((elem) => elem.id === id);
    if (option) {
      return option;
    }
  }
  return null;
}

interface IProps {
  defaultSelectedId?: number;
  onSelect?: (option: IMetadataOption | null) => void;
}

const Tipologia: FC<IProps> = (props) => {

  const tipologie: Array<IMetadataOption> = useSelector((s: RootState) => s.metadata.course.tipologia_corso);

  const defaultSelectedOption = (): IMetadataOption | null => {
    if (props?.defaultSelectedId) {
      return getTipologiaOption(props.defaultSelectedId, tipologie);
    }
    return null;
  };

  const [filterByTipologia, setFilterByTipologia] = useState<IMetadataOption | null>(defaultSelectedOption());

  const handleFilterByTipologia = (option: IMetadataOption | null) => {
    setFilterByTipologia(option);
    if (props?.onSelect) {
      props.onSelect(option);
    }
  };

  return (
    <Fragment>
      <Dropdown className="mx-2 my-2">
        <Dropdown.Toggle variant="outline-primary">
          {filterByTipologia != null ? (
            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill p-1">
              <BsFillCircleFill className="text-success" size={20} title="Filtro attivo" />
            </span>
          ) : ""}
          {`Tipologia ${filterByTipologia ? filterByTipologia.id.toString().substring(0, 10) : ''} `}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-1 border border-primary">
          <Dropdown.Item key={"dropdownTipologiaNone"} disabled={filterByTipologia == null} onClick={() => handleFilterByTipologia(null)}>Tutte</Dropdown.Item>
          {
            tipologie.length ? (
              tipologie.map((item: IMetadataOption, index: number) => {
                return (
                  <Dropdown.Item key={"dropdownTipologia" + item.id} disabled={filterByTipologia != null && item.id === filterByTipologia.id} onClick={() => handleFilterByTipologia(item)}>
                    {item.nome}
                  </Dropdown.Item>
                );
              })
            ) : (
              <Fragment />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default Tipologia;