import { IMetadataOption } from "../entities/metadata.entity";
import { IProfessionalEntity } from "../entities/professional.entity";

export const AdminProfessionalListOrderBy: Array<IMetadataOption> = [
    { id: 1, nome: 'codiceFiscale', descrizione: 'Codice fiscale' },
    { id: 2, nome: 'cognome', descrizione: 'Cognome' },
    { id: 3, nome: 'nome', descrizione: 'Nome' },
    { id: 4, nome: 'stato', descrizione: 'Stato' },
    { id: 5, nome: 'email', descrizione: 'Email' },
    { id: 6, nome: 'dataPrimaIscrizione', descrizione: 'Data prima iscrizione' },
    //{ id: 7, nome: 'idProfessionista', descrizione: 'ID professionista' },
    //{ id: 8, nome: 'titolo', descrizione: 'Titolo' },
    //{ id: 9, nome: 'dataIscrizione', descrizione: 'Data iscrizione' },
];

export const professionalInit: IProfessionalEntity = {
    codiceFiscale: '',
    cognome: '',
    dataIscrizione: '',
    dataPrimaIscrizione: '',
    email: '',
    idProfessionista: 0,
    nome: '',
    note: '',
    ordine: 0,
    pec: '',
    titolo: '',
} as IProfessionalEntity;